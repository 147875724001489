import axios from "axios";

class Facets {

    sortBy(url, value) {
        // title_asc
        const params = value.split('_');
        url.searchParams.set('orderby', params[0]);
        url.searchParams.set('order', params[1].toUpperCase());

        let urlRequest = `${url.href}&is-json=true`;

        if (url.href.indexOf('?') === -1) {
            urlRequest = `${url.href}?is-json=true`;
        }

        this.applyFilterAjax(urlRequest);
        history.pushState({}, null, url.href);
    }

    applyFilter(url, item) {
        const value = item.dataset.slug;
        let filter = item.dataset.filter;

        if (filter) {
            filter = 'filter_' + filter;
            let currentValues = url.searchParams.get(filter);

            if (currentValues) {
                currentValues = currentValues.split(',');

                if (value && currentValues.indexOf(value) !== -1) {
                    item.classList.add('active');
                    item.checked = true;

                    if (item.classList.contains('input-theme')) {
                        item.closest(".theme-wrap").classList.add('active');
                    } else if (item.classList.contains('input-subtheme')) {
                        item.closest(".subtheme-wrap").classList.add('active');
                    } else if (item.classList.contains('input-doc-type')) {
                        item.closest(".doc-type-wrap").classList.add('active');
                    }
                } else {
                    item.classList.remove('active');
                    item.checked = false;

                    if (item.classList.contains('input-theme')) {
                        item.closest(".theme-wrap").classList.remove('active');
                    } else if (item.classList.contains('input-subtheme')) {
                        item.closest(".subtheme-wrap").classList.remove('active');
                    } else if (item.classList.contains('input-doc-type')) {
                        item.closest(".doc-type-wrap").classList.remove('active');
                    }
                }
            } else {
                item.classList.remove('active');
                item.checked = false;

                if (item.classList.contains('input-theme')) {
                    item.closest(".theme-wrap").classList.remove('active');
                } else if (item.classList.contains('input-subtheme')) {
                    item.closest(".subtheme-wrap").classList.remove('active');
                } else if (item.classList.contains('input-doc-type')) {
                    item.closest(".doc-type-wrap").classList.remove('active');
                }
            }
        }
    }

    setFilter(url, item, sendRequest = true) {
        const value = item.dataset.slug;
        let filter = item.dataset.filter;

        if (value && filter) {
            filter = 'filter_' + filter;
            let currentValues = url.searchParams.get(filter);

            if (currentValues) {
                currentValues = currentValues.split(',');

                if (currentValues.indexOf(value) !== -1) {
                    currentValues.splice(currentValues.indexOf(value), 1);
                } else {
                    currentValues.push(value);
                }

                if (currentValues.length === 0) {
                    url.searchParams.delete(filter);
                } else {
                    url.searchParams.set(filter, currentValues.join(','));
                }
            } else {
                url.searchParams.set(filter, value);
            }

            let newUrl = url.href.split('/page');
            if (newUrl.length !== 1) {
                url.href = newUrl[0] + '/?' + newUrl[1].split('/?')[1];
            }
        }

        history.pushState({}, null, url.href);

        if (sendRequest) {
            let urlRequest = `${url.href}&is-json=true`;

            if (url.href.indexOf('?') === -1) {
                urlRequest = `${url.href}?is-json=true`;
            }

            this.applyFilterAjax(urlRequest);
        }

        return url;
    }

    selectSubtheme(item) {
        const themeWrap = item.closest('.theme');
        return themeWrap.querySelectorAll('.input-subtheme');
    }

    applyFilterAjax(url) {
        const self = this;

        const ajaxWrapper = document.querySelector('.ajax-wrapper');
        if (ajaxWrapper) {
            ajaxWrapper.classList.add('loading');
        }

        axios
            .get(url, {})
            .then(r => {
                const ajaxWrapper = document.querySelector('.ajax-wrapper');

                if (ajaxWrapper) {
                    self.changeContentAjax(ajaxWrapper, r.data.data);
                    ajaxWrapper.classList.remove('loading');
                }
            })
            .catch(err => console.log('err : ', err));
    }

    changeContentAjax(element, content) {
        element.querySelectorAll('.tab-pane').forEach((tab) => {
            tab.remove();
        });

        element.innerHTML += content;

        const tabArticles = element.querySelector('#tabs-articles');
        if (tabArticles) {
            document.querySelector('.tabs-articles').innerText = 'Articles (' + tabArticles.dataset.length + ')';
        }

        const tabFiles = element.querySelector('#tabs-files');
        if (tabFiles) {
            document.querySelector('.tabs-files').innerText = 'Fichiers (' + tabFiles.dataset.length + ')';
        }

        this.handleServiceAchatBtn();
    }

    handleServiceAchatBtn() {
        const url = new URL(window.location.href);

        const servicePlusParam = url.searchParams.get('filter_arbo_services_plus');
        const btnServiceAchats = document.querySelector('#btn-service-achats');

        if (btnServiceAchats) {
            btnServiceAchats.classList.remove('d-none');

            if (servicePlusParam === null || (!servicePlusParam.includes('service-achats') && !servicePlusParam.includes('prestations'))) {
                btnServiceAchats.classList.add('d-none');
            }
        }
    }
}

export default new Facets();
