class PromiseRewenal {
    init() {
        this.lockPromiseRewenalCards();
    }

    lockPromiseRewenalCards() {
        let promiseCards = document.querySelectorAll('.card-promise-rewenal');
        if (promiseCards) {
            for (const promiseCard of promiseCards) {
                /** Add card promise rewenal */
                const lockCard = document.createElement('div');
                lockCard.classList.add('card-promise-rewenal-lock');

                /** Add icon promise rewenal */
                const lockIcon = document.createElement('i');
                lockIcon.classList.add('icon-lock');
                
                /** Loop on cards and add lock */
                lockCard.append(lockIcon);
                promiseCard.append(lockCard);
            }
        }
    }
}

export default new PromiseRewenal();