// import _ from 'lodash'
import clndr from 'clndr';
import moment from 'moment';

class Calendar {

    init() {
        let theCalendarInstance;
        const agenda = $('.agenda_france');

        if (agenda.length > 0) {
            moment.locale('fr');
            const events = this.getEvents();
            theCalendarInstance = agenda.clndr({
                template: $('#calendar-template').html(),
                daysOfTheWeek: ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'],
                moment: moment,
                multiDayEvents: {
                    endDate: 'end',
                    singleDay: 'date',
                    startDate: 'start'
                },
                events: events,
                clickEvents: {
                    click: function(target) {

                        const daysActive = document.querySelectorAll('.day.active');
                        if (daysActive) {
                            daysActive.forEach( day => {
                                day.classList.remove('active');
                            })
                        }

                        target.element.classList.toggle('active');

                        const eventsCardActive = document.querySelectorAll('.agenda-event.active');
                        if (eventsCardActive) {
                            eventsCardActive.forEach(eventCard => {
                                eventCard.classList.remove('active');
                            })
                        }

                        target.events.forEach( event => {
                            const eventCardToShow = document.querySelector(`.agenda-event[data-id="${event.id}"]`);
                            if (eventCardToShow) {
                                eventCardToShow.classList.add('active');
                            }
                        })
                    },
                    nextMonth: this.removeCards,
                    previousMonth: this.removeCards,
                }
            });
        }
    }

    getEvents() {
        const events = document.querySelectorAll('.agenda-event');
        let eventsFormat = [];

        if (events) {
            events.forEach( el => {
                let eventObj = {};
                let eventStart = el.getAttribute('data-start');
                let eventEnd = el.getAttribute('data-end');

                if (eventStart === eventEnd) {
                    eventObj.date = eventStart;
                } else {
                    eventObj.start = eventStart;
                    eventObj.end = eventEnd;
                }

                eventObj.id = el.getAttribute('data-id');
                eventsFormat.push(eventObj);
            })
        }

        return eventsFormat;
    }

    removeCards() {
        const eventsCardActiveToRemove = document.querySelectorAll('.agenda-event.active');

        if (eventsCardActiveToRemove) {
            eventsCardActiveToRemove.forEach(event => {
                event.classList.remove('active');
            })
        }
    }
}

export default new Calendar();