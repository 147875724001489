import axios from "axios";

const delay = (callback, ms) => {
  let timer = 0;
  return () => {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, ms || 0);
  };
};

const resetResults = () => {
  const resultsElement = document.querySelector('.results');
  const resultContainer = resultsElement.querySelector('.result-content');
  if (resultContainer !== null) {
    resultsElement.classList.remove('visible');
    resultContainer.innerHTML = '';
  }
};

const buildResultLine = (result) => {
  const mainElement = document.createElement('div');
  mainElement.classList.add('item');

  const label = document.createElement('div');
  label.classList.add('label');
  label.innerText = result.properties.label;

  const content = document.createElement('div');
  content.classList.add('content');
  content.innerText = result.properties.postcode;

  mainElement.append(label);
  mainElement.append(content);

  return mainElement;
};

const fetchResult = (apiEndpointPath, query, resultsElement) => {
  const resultContainer = resultsElement.querySelector('.result-content');
  axios.get(apiEndpointPath + query)
    .then((response) => {
      resetResults();
      Array.prototype.filter.call(response.data.features, (result) => {
        const line = buildResultLine(result);
        resultContainer.append(line);

        line.addEventListener('click', () => {
          const input = document.getElementById('input-location');
          input.value = line.querySelector('.label').innerText + ', ' + line.querySelector('.content').innerText;
          resetResults();
        });
      });

      if (response.data.features.length > 0) {
        resultsElement.classList.add('visible');
      }
    })
    .catch(error => console.error(error));
};

const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

const hideOnClickOutside = (element) => {
  const outsideClickListener = event => {
    if (element !== null) {
      if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
        resetResults();
      }
    }
  };

  document.addEventListener('click', outsideClickListener);
};


const autocompleteSearch = (apiEndpointPath) => {
  const autocomplete = document.getElementById('input-location');
  const resultsElement = document.querySelector('.results');

  if (autocomplete !== null && resultsElement !== null) {
    autocomplete.addEventListener('keyup', delay(() => {
      if (autocomplete.value.length >= 3) {
        fetchResult(apiEndpointPath, autocomplete.value + '&type=municipality&autocomplete=1&limit=15', resultsElement);
      }
    }, 500));
  }

  hideOnClickOutside(resultsElement);
};

export default autocompleteSearch;
