import Glide from '@glidejs/glide';

export default class FrontPage {
    constructor() {
        this.init();
        this.childLinks();
    }

    init() {
        const glidesActus = document.querySelector('.slide__postsActu');
        const glidesAgenda = document.querySelector('.slide__postsAgenda');
        const glidesVideo = document.querySelector('.slide__videos');

        const args = {
            type: 'slider',
            startAt: 0,
            perView: 3,
            rewind: false,
            swipeThreshold: 10,
            breakpoints: {
                1024: {
                    perView: 2,
                    peek: 150,
                },
                768: {
                    perView: 1,
                    focusAt: 'center',
                    peek: 100,
                },
                550: {
                    perView: 1,
                    focusAt: 'center',
                    peek: 20,
                }
            }
        };

        const argsVideos = {
            type: 'slider',
            startAt: 0,
            perView: 1,
            focusAt: 'center',
            peek: 600,
            breakpoints: {
                2100: {
                    peek: 500,
                },
                1920: {
                    peek: 400,
                },
                1600: {
                    peek: 300,
                },
                1300: {
                    peek: 200,
                },
                1024: {
                    peek: 150,
                },
                768: {
                    peek: 100,
                },
                550: {
                    peek: 20,
                }
            }
        };

        if (glidesActus) {
            new Glide('.slide__postsActu', args).mount();
            this.createBullets(glidesActus);
        }

        if (glidesAgenda) {
            new Glide('.slide__postsAgenda', args).mount();
            this.createBullets(glidesAgenda);
        }

        if (glidesVideo) {
            new Glide('.slide__videos', argsVideos).mount();
            this.createBullets(glidesVideo);
        }
    }

    createBullets(el) {
        const countSlides = el.querySelectorAll('.glide__slides > *:not(.glide__slide--clone)').length;

        for (let i = 0; i < countSlides; i++) {
            el.querySelector('.glide__bullets').innerHTML += `<button class="glide__bullet" data-glide-dir="=${i}"></button>`;
        }
    }

    childLinks() {
        const glidesChildLinks = document.querySelector('.slide__childLinks');

        const args = {
            type: 'slider',
            startAt: 0,
            perView: 4,
            keyboard: false,
            breakpoints: {
                1024: {
                    peek: 150,
                    perView: 2
                },
                768: {
                    peek: 100,
                    perView: 1,
                    focusAt: 'center'
                },
                550: {
                    peek: 20,
                    perView: 1,
                    focusAt: 'center'
                }
            }
        };

        if (glidesChildLinks) {
            new Glide('.slide__childLinks', args).mount();
            this.createBullets(glidesChildLinks);
        }
    }
}