
class Menus {
    constructor() {
        this.init();
    }

    init() {
        const btn = document.querySelector('.nav-extranet');
        const blkMenu = document.querySelector('.blk-menu-adherent');
        const btnsNavItem = document.querySelectorAll('nav#menu-adherent > ul > li.nav-item');
        const btnMainMenu = document.querySelector('.btn-menu-main');
        const hamburger = document.querySelector('.hamburger');
        const mainMenu = document.querySelector('.blk-menu-main');
        const btnsSubMenu = document.querySelectorAll('.blk-menu-main .blk-left > ul > li > a');

        if (blkMenu) {
            const linksMenu = blkMenu.querySelectorAll('.nav-link');
            const blkRight = blkMenu.querySelector('.blk-right');
            const btnUsername = blkRight.querySelector('.blk-username');

            btn.addEventListener('click', (event) => {
                btn.classList.toggle('open');
                blkMenu.classList.toggle('open');
                if (hamburger) {
                    hamburger.classList.remove('is-active');
                }
                if (mainMenu) {
                    mainMenu.classList.remove('open');
                }

                if (btn.classList.contains("open")) {
                    Array.prototype.filter.call(linksMenu, (linkMenu) => {
                        linkMenu.setAttribute('tabindex', "0");
                    });
                } else {
                    Array.prototype.filter.call(linksMenu, (linkMenu) => {
                        linkMenu.setAttribute('tabindex', "-1");
                    });
                }
            });

            btnUsername.addEventListener('click', (event) => {
                if (window.matchMedia("(max-width: 768px)").matches) {
                    blkRight.classList.toggle('open');
                }
            });
        }

        if (btnsNavItem) {
            Array.prototype.filter.call(btnsNavItem, (btnNavItem) => {
                btnNavItem.addEventListener('click', (event) => {
                    let targetElement = event.target || event.srcElement

                    if (targetElement.classList.contains("menu-item-has-children")) {
                        event.preventDefault();
                    }

                    if (targetElement.classList.contains("nav-item")) {
                        targetElement.classList.toggle('active');
                    }

                    if (targetElement.classList.contains("menu-item")) {
                        targetElement.parentElement.classList.toggle('active');
                    }
                });
            });
        }

        if (btnMainMenu) {
            btnMainMenu.addEventListener('click', (event) => {
                hamburger.classList.toggle('is-active');
                mainMenu.classList.toggle('open');
                btn.classList.remove('open');
                blkMenu.classList.remove('open');

                const linksMainMenu = mainMenu.querySelectorAll('.nav-link');

                if (mainMenu.classList.contains("open")) {
                    Array.prototype.filter.call(linksMainMenu, (linkMainMenu) => {
                        linkMainMenu.setAttribute('tabindex', "0");
                    });
                } else {
                    Array.prototype.filter.call(linksMainMenu, (linkMainMenu) => {
                        linkMainMenu.setAttribute('tabindex', "-1");
                    });
                }
            });

            Array.prototype.filter.call(btnsSubMenu, (btnSubMenu) => {
                btnSubMenu.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        btnSubMenu.parentNode.classList.toggle('open');
                    }
                });
            });
        }


    }
}

export default Menus;
