
export default class Contact {
    constructor() {
        this.init();
    }

    init() {
        const options = document.querySelectorAll('.page-contact select > option:first-child');

        for (let option of options) {
            option.setAttribute("selected", "");
            option.setAttribute("disabled", "");
            option.setAttribute("hidden", "");
        }
    }
}