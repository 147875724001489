import Swiper from 'swiper/swiper-bundle.js';
import Glide from '@glidejs/glide'

export default class Sliders {
    constructor() {
        this.init();
    }

    init() {
        const bullets = document.querySelectorAll('.glide__bullet');

        if (bullets) {
            for (let bullet of bullets) {
                bullet.addEventListener('click', (event) => {
                    event.preventDefault();
                });
            }
        }

        const blksGallery = document.querySelectorAll('.wp-block-gallery');
        if (blksGallery) {
            for (let gallery of blksGallery) {

                let glideTrack = document.createElement('div');
                glideTrack.classList.add('glide__track');
                glideTrack.setAttribute('data-glide-el', 'track');
                gallery.appendChild(glideTrack);

                let glideSlides = document.createElement('div');
                glideSlides.classList.add('glide__slides');
                glideTrack.appendChild(glideSlides);

                let buttonPrev = document.createElement('button');
                buttonPrev.classList.add('glide__arrow');
                buttonPrev.classList.add('glide__arrow--prev');
                buttonPrev.setAttribute('data-glide-dir', '<');

                let buttonNext = document.createElement('button');
                buttonNext.classList.add('glide__arrow');
                buttonNext.classList.add('glide__arrow--next');
                buttonNext.setAttribute('data-glide-dir', '>');

                let glideArrows = document.createElement('div');
                glideArrows.classList.add('glide__arrows');
                glideArrows.setAttribute('data-glide-el', 'controls');

                glideArrows.appendChild(buttonPrev);
                glideArrows.appendChild(buttonNext);
                gallery.appendChild(glideArrows);

                let slides = gallery.querySelectorAll('.wp-block-image');
                if (slides) {
                    let glideBullets = document.createElement('div');
                    glideBullets.classList.add('glide__bullets');
                    glideBullets.setAttribute('data-glide-el', 'controls[nav]')

                    let index = 0;
                    for (let slide of slides) {
                        glideSlides.appendChild(slide);

                        let bullet = document.createElement('button');
                        bullet.classList.add('glide__bullet');
                        bullet.setAttribute('data-glide-dir', '=' + index);
                        glideBullets.appendChild(bullet);
                        index++;
                    }
                    gallery.appendChild(glideBullets);
                }

                new Glide(gallery, {
                    type: 'carousel',
                    autoplay: 0,
                    animationDuration: 600,
                    animationTimingFunc: 'linear',
                    perView: 1,
                    peek: 100,
                    focusAt: 0,
                    breakpoints: {
                        768: {
                            peek: 0
                        }
                    }
                }).mount();
            }
        }
    }
}
