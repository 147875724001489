import Glide from '@glidejs/glide'

class Alert {
    constructor() {
        this.init();
    }

    init() {
        const glides = document.querySelector('.glide');

        if (glides) {
            new Glide('.glide').mount();
        }
    }
}

export default Alert;
