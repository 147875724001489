
export default class Share {
    constructor() {
        this.init();
    }

    init() {
        const btns = document.querySelectorAll('.popup-share .popup-content > *');
        const btnLink = document.querySelector('.popup-share .popup-content > div.icon-url');
        const popover = document.querySelector('.popover');

        for (let btn of btns) {
            btn.addEventListener('mouseenter', function (event) {
                popover.textContent = btn.dataset.survol;
                popover.dataset.rs = btn.dataset.rs;

                popover.classList.add('active');

                switch (btn.dataset.rs) {
                    case 'facebook':
                        popover.style.top = "15px";
                        break
                    case 'twitter':
                        popover.style.top = "75px";
                        break
                    case 'linkedin':
                        popover.style.top = "135px";
                        break
                    case 'mail':
                        popover.style.top = "195px";
                        break
                    case 'url':
                        popover.style.top = "255px";
                        break
                }
            });

            btn.addEventListener('mouseout', function (event) {
                popover.classList.remove('active');
            });
        }

        if (btnLink) {
            btnLink.addEventListener('click', function (event) {
                const btnUrl = document.getElementById('url-copy');
                btnUrl.select();
                document.execCommand( 'copy');

                popover.textContent = btnLink.dataset.click;
            });
        }
    }
}