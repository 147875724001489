const C = {
    DOMAIN: 'https://documents.lesentreprisesdupaysage.fr/'
    // DOMAIN: 'https://documents.lesentreprisesdupaysage.test/'
}

export async function get(path, params, pathComplete = false){
    return await request(path, params, false, 'GET', pathComplete);
}

export async function post(path, params, rawBody = false, pathComplete = false){
    return await request(path, params, rawBody, 'POST', pathComplete);
}

export async function put(path, params, rawBody = false, pathComplete = false){
    return await request(path, params, rawBody, 'PUT', pathComplete);
}

export async function remove(path, params, rawBody = false, pathComplete = false){
    return await request(path, params, rawBody, 'DELETE', pathComplete);
}

function serialize(obj) {
    return Object.keys(obj).reduce(function(a,k){a.push(k+'='+obj[k]);return a},[]).join('&');
}

async function request(path, params, rawBody, method, pathComplete){
    var query = "";

    let requestOptions = {
        method: method,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        },
    };

    let url = getUrl(path);
    if(pathComplete){
        url = path;
    }

    let token = await getAuthToken();

    if(method === 'GET'){
        if(params){
            if(!url.includes('?')) {
                url = url + '?timestamp='+ new Date().getTime()+'&'
            }
            else {
                url = url + '&timestamp='+ new Date().getTime()+'&';
            }

            url = url + serialize(params)
        }

        requestOptions.headers['Content-Type'] = "application/json";
        requestOptions.headers['Accept'] = "application/json"; //"application/x-www-form-urlencoded";
    } else {
        if(params){
            if(rawBody) {
                query = JSON.stringify(params);
                requestOptions.headers['Content-Type'] = "application/json";
                requestOptions.headers['Accept'] = "application/json";
            } else {
                query = new FormData();

                if(params && typeof params !== "undefined") {
                    let keys = Object.keys(params);
                    keys.forEach(key => {
                        let param = params[key]

                        if(typeof param === 'boolean'){
                            param = JSON.stringify(params[key]);
                        }

                        if(typeof param === 'object' && param.hasOwnProperty('0')){
                            if(process.env.NODE_ENV === "development" ){
                                console.log(param);
                            }
                            param = param['0'];
                        }

                        query.append(key, param)
                    });
                }

                //requestOptions.headers['Content-Type'] = "multipart/form-data";
            }

            requestOptions.body = query;
        }

        if(method === 'DELETE'){
            requestOptions.mode = 'cors';
        }
    }

    if(token){
        requestOptions.headers['Authorization'] = 'Bearer ' + token;
    }

    if(process.env.NODE_ENV === "development" ){
        console.log('request : ', url, requestOptions);
    }

    const response = await fetch(url, requestOptions);

    if(response){
        const data = response.json();
        if(process.env.NODE_ENV === "development" ){
            console.log('response : ', await data);
        }
        return data;
    }

    return false;
}

function getUrl(path, params = false) {
    const DOMAIN = C.DOMAIN;

    let url = DOMAIN+'api/'+path;

    if(params){
        if(!url.includes('?')) {
            url = url + '?'
        }
        else {
            url = url + '&';
        }

        url = url + serialize(params)
    }

    return url;
}

async function getAuthToken() {
    let token = JSON.parse(localStorage.getItem('unep-token'));
	const tokenDate = JSON.parse(localStorage.getItem('unep-duration'));
	const now = Date.now();
	const duration = now - tokenDate;
	const durationMax = 60000;

	if(!token || duration > durationMax){
        let requestOptions = {
            method: 'GET',
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            },
        };

        let url = window.location.origin+'/api/gestion-documentaire/token'
        requestOptions.headers['Content-Type'] = "application/json";
        requestOptions.headers['Accept'] = "application/json"; //"application/x-www-form-urlencoded";

        const response = await fetch(url, requestOptions);
        const data = await response.json();
		if(data.hasOwnProperty('status') && data.status === 'success' && data.hasOwnProperty('token') && data.token){
			token = data.token;
			localStorage.setItem('unep-token', JSON.stringify(token));
			localStorage.setItem('unep-duration', JSON.stringify(now));
		}
	}

	return token;
}
