export default class MiniModal {
    constructor() {
        this.init();
    }

    init() {
        const miniModal = document.querySelector('.mini-modal');
        if (!miniModal || this.getCookie('ledp-mini-modal')) {
            return;
        }

        setTimeout(() => {
            miniModal.classList.add('open');
        }, 1000);

        const btnClose = miniModal.querySelector('.close');
        if (btnClose) {
            btnClose.addEventListener('click', (event) => {
                event.preventDefault();
                miniModal.classList.remove('open');
                miniModal.classList.add('closed');

                let expireDate = new Date();
                expireDate.setTime(expireDate.getTime() + (4*24*60*60*1000));

                this.setCookie('ledp-mini-modal', 1, expireDate);
            });
        }
    }

    setCookie(cName, cValue, expireDate) {
        let expires = "expires=" + expireDate.toUTCString();
        document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    }

    getCookie(cName) {
        let name = cName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
