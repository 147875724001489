import axios from "axios";

class GlobalSearch {

    init() {
        const self = this;

        this.sortWithSelect();

        const items = document.querySelectorAll('.sort-item');
        const url = new URL(window.location.href);

        if (items) {
            Array.prototype.filter.call(items, function (item) {

                const value = item.dataset.slug;
                let filter = item.dataset.filter;

                if (value && filter) {
                    let currentValues = url.searchParams.get(filter);
                    if (currentValues) {
                        currentValues = currentValues.split(',');

                        if (currentValues.indexOf(value) !== -1) {
                            item.classList.add('active');
                            item.checked = true;
                        }
                    }
                }

                item.addEventListener('click', (event) => {
                    event.stopPropagation();

                    if (event.target.classList.contains('sort-item')) {
                        const value = item.dataset.slug;
                        let filter = item.dataset.filter;

                        if (value && filter) {
                            let currentValues = url.searchParams.get(filter);

                            if (currentValues) {
                                currentValues = currentValues.split(',');

                                if (currentValues.indexOf(value) !== -1) {
                                    currentValues.splice(currentValues.indexOf(value), 1);
                                } else {
                                    currentValues.push(value);
                                }

                                if (currentValues.length === 0) {
                                    url.searchParams.delete(filter);
                                } else {
                                    url.searchParams.set(filter, currentValues.join(','));
                                }
                            } else {
                                url.searchParams.set(filter, value);
                            }

                            let newUrl = url.href.split('/page');

                            if (newUrl.length !== 1) {
                                url.href = newUrl[0] + '/?' + newUrl[1].split('/?')[1];
                            }
                        }

                        const ajaxWrapper = document.querySelector('.ajax-wrapper');
                        if (ajaxWrapper) {
                            ajaxWrapper.classList.add('loading');
                        }

                        let urlRequest = `${url.href}&is-json=true`;

                        if (url.href.indexOf('?') === -1) {
                            urlRequest = `${url.href}?is-json=true`;
                        }

                        axios
                            .get(urlRequest, {})
                            .then(r => {
                                const wrapper = document.querySelector('#global-search-wrapper');
                                const searchResults = document.querySelector('.search-results-content');
                                const sortWrapper = document.querySelector('#wrap-sort-by');

                                if (searchResults && wrapper) {
                                    searchResults.remove();
                                    sortWrapper.remove();
                                    wrapper.innerHTML += r.data.data;
                                }

                                const items = document.querySelectorAll('.sort-item');
                                const url = new URL(window.location.href);

                                if (items) {
                                    items.forEach((item) => {
                                        item.classList.remove('active');
                                        item.checked = false;

                                        const value = item.dataset.slug;
                                        let filter = item.dataset.filter;

                                        if (value && filter) {
                                            let currentValues = url.searchParams.get(filter);
                                            if (currentValues) {
                                                currentValues = currentValues.split(',');

                                                if (currentValues.indexOf(value) !== -1) {
                                                    item.classList.add('active');
                                                    item.checked = true;
                                                }
                                            }
                                        }
                                    });
                                }

                                if (ajaxWrapper) {
                                    ajaxWrapper.classList.remove('loading');
                                }

                                self.sortWithSelect();
                                self.pagination();
                            })
                            .catch(err => console.log('err : ', err));
                        history.pushState({}, null, url.href);
                    }
                });
            });
        }

        this.breadcrumb();
    }

    sortWithSelect() {
        const url = new URL(window.location.href);
        const $sortBySelect = $('#sort_result_by');
        const $filterByDate = $('#filter_by_year');

        const defaultOrderBy = url.searchParams.get("orderby");
        const defaultOrder = url.searchParams.get("order");

        const defaultFilterByDate = url.searchParams.get("sort_published_year") ? url.searchParams.get("sort_published_year") : 'all';

        let defaultSortBy = '';

        if (defaultOrder && defaultOrderBy) {
            defaultSortBy = defaultOrderBy + '_' + defaultOrder.toLowerCase();
        } else {
            defaultSortBy = 'relevance_desc';
        }

        if (defaultSortBy) {
            let $select = $sortBySelect.selectize();
            let selectize = $select[0].selectize;
            selectize.setValue(defaultSortBy, false);
        }

        if (defaultFilterByDate) {
            let $selectDate = $filterByDate.selectize();
            let selectizeDate = $selectDate[0].selectize;
            selectizeDate.setValue(defaultFilterByDate, false);
        }

        $sortBySelect.on('change', (event) => {
            const params = event.target.value.split('_');
            url.searchParams.set('orderby', params[0]);
            url.searchParams.set('order', params[1].toUpperCase());
            // set new url
            window.location.href = url.href;
        });

        $filterByDate.on('change', (event) => {
            url.searchParams.set('sort_published_year', event.target.value);
            // set new url
            window.location.href = url.href;
        });
    }

    breadcrumb() {
        // Gère le breadcrumb des cards results
        const url = window.location.origin;
        const posts = document.querySelectorAll('.card-doc-breadcrumb');

        posts.forEach((post) => {
            const postId = post.dataset.id;
            const breadcrumb = post.querySelector('.card-breadcrumb');

            axios
                .get(`${window.location.origin}/wp-json/bcn/v1/post/${post.dataset.id}`, {})
                .then(r => {
                    r.data.itemListElement.forEach(
                        (item, index) => {
                            const a = document.createElement('a');
                            a.setAttribute('href', item.item[Object.keys(item.item)[0]]);
                            a.text = window.htmlentities.decode(item.item.name);

                            if (breadcrumb) {
                                breadcrumb.append(a);
                            }
                        }
                    );
                    const region = breadcrumb.querySelector('a.region');
                    if (region) {
                        breadcrumb.appendChild(region);
                    }
                })
                .catch(err => console.error({err}));
        });

    }

    pagination() {
        const paginationItems = document.querySelectorAll('.tool-pagination .pages li a');

        paginationItems.forEach(item => {
            if (item.href.includes('&is-json=true')) {
                item.href = item.href.replace('&is-json=true', '');
            }

            if (item.href.includes('?is-json=true')) {
                item.href = item.href.replace('?is-json=true', '');
            }
        });
    }

}

export default new GlobalSearch;
