export default class Press {
    constructor() {
        this.init();
    }

    init() {
        this.downloadFile();
    }

    downloadFile() {
        const btns = document.querySelectorAll('.card-document .btn');
        Array.prototype.filter.call(btns, function (btn) {
            btn.addEventListener('click', (event) => {
                const form = btn.closest('form');
                if (form !== null) {
                    event.preventDefault();
                    form.submit();
                }
            });
        });
    }
}
