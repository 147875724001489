export default class Copy {
    constructor() {
        this.init();
    }

    init() {
        const buttons = document.querySelectorAll(".copy-email");
        const self = this;
        for (const button of buttons) {
            button.addEventListener('click', function () {
                const str = this.getAttribute('data-email');
                this.firstChild.data = "Email copié";
                self.copyStringToClipboard(str);
            })
        }
    }

    copyStringToClipboard (str) {
        // Create new element
        const el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }
}