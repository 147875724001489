class Forms {
    toggleCardForm(card, btn) {
        btn.classList.toggle('d-none');
        card.classList.toggle('active');
        card.querySelector('.btn-to-toggle').classList.toggle('d-none');

        const inputs = card.querySelectorAll('input');
        Array.prototype.filter.call(inputs, (input) => {
            const acfParent = input.closest('.acf-input');

            if (!acfParent) {
                if (input.type !== "checkbox" && input.type !== "file") {
                    input.classList.toggle('form-control-plaintext');
                    input.toggleAttribute('readonly');
                } else {
                    input.toggleAttribute('disabled');
                }
            }
        });

        const textareas = card.querySelectorAll('textarea');
        Array.prototype.filter.call(textareas, (textarea) => {
            textarea.classList.toggle('form-control-plaintext');
            textarea.toggleAttribute('readonly');
        });

        const wpeditors = card.querySelectorAll('.wp-editor-wrap');
        Array.prototype.filter.call(wpeditors, (wpeditor) => {
            wpeditor.classList.toggle('d-none');
            const parent = wpeditor.closest('.card');
            const text = parent.querySelector('.wpeditor-text');

            if (text) {
                text.classList.toggle('d-none');
            }
        });

        const pictures = card.querySelectorAll('.picture-wrapper');
        Array.prototype.filter.call(pictures, (picture) => {
            picture.classList.toggle('disabled');
        });

        const inputGroups = card.querySelectorAll('.input-group-text');
        Array.prototype.filter.call(inputGroups, (inputGroup) => {
            inputGroup.classList.toggle('form-control-plaintext');
        });

        const slides = card.querySelectorAll('.slides');
        Array.prototype.filter.call(slides, (slide) => {
            slide.classList.toggle('d-none');
        });

        const cancel = card.querySelector('.cancel');
        cancel.addEventListener('click', (event) => {
            this.toggleCardForm(card, btn);
        });

        const passwords = card.querySelectorAll('.password-block');
        Array.prototype.filter.call(passwords, (block) => {
            block.classList.toggle('visible')
            const helper = block.querySelector('.form-text');

            if (helper) {
                helper.classList.toggle('d-none');
            }
        });

        const blocksToToggle = card.querySelectorAll('.block-to-toggle');
        Array.prototype.filter.call(blocksToToggle, (block) => {
            block.classList.toggle('d-none');
        });

        const publicCompany = document.querySelector('form#update-public-company');

        if (publicCompany) {
            this.preFillingSocialNetwork();
        }
    }

    passwordInput(password) {
        if (password) {
            const btn = password.parentNode.querySelector('.btn-visibility');
            btn.addEventListener('click', (event) => {
                event.preventDefault();

                this.togglePasswordVisibility(password);
            });
        }
    }

    togglePasswordVisibility(element) {
        const parentBlock = element.closest('.input-group');

        if (element.type === "password") {
            element.type = "text";
            parentBlock.classList.add('visible');
        } else {
            element.type = "password";
            parentBlock.classList.remove('visible');
        }
    }

    readURL(input, img) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                img.setAttribute('src', e.target.result);
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    simulateClick(elem) {
        // Create our event (with options)
        const evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });

        // If cancelled, don't dispatch our event
        let canceled = !elem.dispatchEvent(evt);
    }

    preFillingSocialNetwork() {
        const socialNetworks = [
            'company_linkedin',
            'company_facebook',
            'company_twitter',
            'company_instagram'
        ];

        socialNetworks.forEach(id => {
            let input = document.querySelector(`#${id}:not(.form-control-plaintext)`);

            if (input && input.value === '') {
                input.value = 'https://';
            }
        });

        $('form#update-public-company button[type=submit], form#update-public-company button.cancel').click(function(){
            socialNetworks.forEach(id => {
                let input = document.querySelector(`#${id}`);

                if (input.value === 'https://') {
                    input.value = '';
                }
            });
        });
    }
}

export default new Forms();
