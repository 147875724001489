import Facets from './components/facets';
import 'selectize';
import AnimateHeight from './animateHeight';

class BaseDoc {
    init() {
        document.querySelectorAll('.tabs-docs').forEach((tab) => {
            tab.addEventListener('click', (event) => {
                const url = new URL(window.location.href);
                const a = event.target.closest('a');
                url.searchParams.set('tab', a.href.split('#')[1]);
                history.pushState({}, null, url.href);
            });
        });

        this.handleSelection();
        this.sortWithSelect();
        this.sortWithFacets();
        this.handleSubthemeDisplay();
        this.initSubthemeDisplay();


        Facets.handleServiceAchatBtn();
    }

    sortWithSelect() {
        const url = new URL(window.location.href);
        // JQuery because selectize is used
        const $sortBySelect = $('#sort_by');

        const defaultOrderBy = url.searchParams.get("orderby");
        const defaultOrder = url.searchParams.get("order");

        let defaultSortBy = false;

        if (defaultOrder && defaultOrderBy) {
            defaultSortBy = defaultOrderBy + '_' + defaultOrder.toLowerCase();
        }

        if (defaultSortBy) {
            let $select = $sortBySelect.selectize();
            let selectize = $select[0].selectize;
            selectize.setValue(defaultSortBy, false);
        }

        $sortBySelect.on('change', (event) => {
            const url = new URL(window.location.href);
            Facets.sortBy(url, event.target.value);
        });
    }

    sortWithFacets() {
        const items = document.querySelectorAll('.facets-item');
        const self = this;

        if (items) {
            Array.prototype.filter.call(items, function (item) {
                item.addEventListener('click', (event) => {
                    event.stopPropagation();
                    let url = new URL(window.location.href);

                    if (event.target.classList.contains('facets-item')) {
                        url = Facets.setFilter(url, event.target, false);

                        if (event.target.classList.contains('input-theme')) {
                            let subthemeCheck = Facets.selectSubtheme(event.target);

                            Array.prototype.filter.call(subthemeCheck, function (subtheme) {
                                url = Facets.setFilter(url, subtheme, false);
                            });
                        }

                        let urlRequest = `${url.href}&is-json=true`;

                        if (url.href.indexOf('?') === -1) {
                            urlRequest = `${url.href}?is-json=true`;
                        }

                        Facets.applyFilterAjax(urlRequest);
                        self.handleSelection();
                    }
                });
            });
        }
    }

    handleSelection() {
        const items = document.querySelectorAll('.facets-item');
        const url = new URL(window.location.href);
        const self = this;

        if (items) {
            items.forEach(item => {
                Facets.applyFilter(url, item);

                const themeWrap = item.closest('.theme');

                if (!themeWrap) {
                    return;
                }

                const subitems = themeWrap.querySelectorAll('.input-subtheme');
                if (subitems) {
                    subitems.forEach(subitem => {
                        Facets.applyFilter(url, subitem);
                        self.handleSubthemeDisplay();
                    });
                }
            });
        }
    }

    handleSubthemeDisplay() {
        const showSubthemeBtns = document.querySelectorAll('.show-subtheme');
        const subthemesList = document.querySelectorAll('.subtheme-list');

        if (subthemesList) {

            for (const subthemeList of subthemesList) {
                const subthemes = subthemeList.querySelectorAll('.input-subtheme');

                for (const subtheme of subthemes) {
                    if (subtheme.classList.contains('active')) {
                        const isCollapsed = subthemeList.getAttribute('data-collapsed') === 'true';

                        if (isCollapsed) {
                            AnimateHeight.expandSection(subthemeList);
                            subthemeList.setAttribute('data-collapsed', 'false')
                        }
                    }
                }
            }
        }

        if (showSubthemeBtns) {
            for (const button of showSubthemeBtns) {
                button.addEventListener('click', function () {

                    const subthemeList = this.closest('.theme').querySelector('.subtheme-list');
                    const isCollapsed = subthemeList.getAttribute('data-collapsed') === 'true';

                    if (isCollapsed) {
                        AnimateHeight.expandSection(subthemeList)
                        subthemeList.setAttribute('data-collapsed', 'false')
                    } else {
                        AnimateHeight.collapseSection(subthemeList)
                    }

                    if (this.classList.contains('show-more')) {
                        this.innerHTML = `
                                <svg class="mr-1" height="10" viewBox="0 0 30 30" width="10" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 12h30v5h-30z" fill="#004855" fill-rule="evenodd"/>
                                </svg>
                                Afficher moins`
                        this.classList.remove('show-more');
                        this.classList.add('show-less');
                    } else if (this.classList.contains('show-less')) {
                        this.innerHTML = `
                                <svg class="mr-1" height="10" viewBox="0 0 30 30" width="10" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#004855" fill-rule="evenodd"><path d="m0 12h30v5h-30z"/>
                                        <path d="m13 0h5v30h-5z"/>
                                    </g>
                                </svg>
                                Afficher les sous-thèmes`

                        this.classList.remove('show-less');
                        this.classList.add('show-more');
                    }
                })
            }
        }
    }

    initSubthemeDisplay() {
        const showSubthemeBtns = document.querySelectorAll('.subtheme-list[data-collapsed="false"] + .show-subtheme');

        if (showSubthemeBtns) {

            for (const button of showSubthemeBtns) {

                if (button.classList.contains('show-more')) {
                    button.innerHTML = `
                                <svg class="mr-1" height="10" viewBox="0 0 30 30" width="10" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 12h30v5h-30z" fill="#004855" fill-rule="evenodd"/>
                                </svg>
                                Afficher moins`
                    button.classList.remove('show-more');
                    button.classList.add('show-less');
                }
            }
        }
    }
}
export default new BaseDoc();
