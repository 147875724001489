import Packery from './lib/packery.pkgd.js';
import Draggabilly from './lib/draggabilly.pkgd.js';

export default class moveBlks {
    constructor() {
        this.init();
    }

    init() {
        const grid = document.querySelector('.grid');

        if (grid) {
            const pckry = new Packery( '.grid', {
                itemSelector: '.grid-item'
            });

            const arrayOrderSections = document.getElementById('array-order-sections');
            const btnDragItem = document.getElementById('btn-drag-item');

            pckry.getItemElements().forEach( function( itemElem ) {
                const draggie = new Draggabilly( itemElem );
                pckry.bindDraggabillyEvents( draggie );
            });

            // show item order after layout
            function orderItems() {
                let arraySection = "";

                pckry.getItemElements().forEach( function( itemElem, i ) {
                    if (i !== 0) {
                        arraySection += ",";
                    }
                    arraySection += itemElem.dataset.idTemplate;
                });

                arrayOrderSections.value = arraySection;
                btnDragItem.classList.add('active');
            }

            pckry.on( 'layoutComplete', function () {
                setTimeout(() => {
                    orderItems();
                }, 500);
            });
            pckry.on( 'dragItemPositioned', orderItems );
        }
    }
}
