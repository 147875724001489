class AnimateHeight {

    collapseSection(element) {
        let sectionHeight = element.scrollHeight;

        let elementTransition = element.style.transition;
        element.style.transition = '';

        requestAnimationFrame(function() {
            element.style.height = sectionHeight + 'px';
            element.style.transition = elementTransition;

            requestAnimationFrame(function() {
                element.style.height = 0 + 'px';
            });
        });

        element.setAttribute('data-collapsed', 'true');
    }

    expandSection(element) {
        // get the height of the element's inner content, regardless of its actual size
        let sectionHeight = element.scrollHeight;

        // have the element transition to the height of its inner content
        element.style.height = sectionHeight + 'px';

        // when the next css transition finishes (which should be the one we just triggered)
        element.addEventListener('transitionend', function(e) {
            element.setAttribute('data-collapsed', 'false');
        }, { once: true });
    }
}

export default new AnimateHeight();