import Glide from '@glidejs/glide';

export default class Card {
    constructor() {
        this.init();
    }

    init() {
        const glidesNewsRegion = document.querySelector('.slide__news--region');
        const glidesNewsFrance = document.querySelector('.slide__news--france');
        const glidesNewsWithoutTabs = document.querySelector('.slide__news--without-tabs');
        const glidesPresDeChezVous = document.querySelector('.slide__pres-de-chez-vous');
        const glidesRealisations = document.querySelector('.slide__realisation');
        const glidesLogoPartenaire = document.querySelector('.slide__logo-partenaire');
        const glidesEntrepriseRecente = document.querySelector('.slide__entreprise-recente');
        const glidesLecturesComplementaires = document.querySelector('.slide__lectures-complementaires');
        const glidesQuestionReponse = document.querySelector('.slide__question-reponse');
        // const glidesQRSocial = document.querySelector('.slide__qr-social');
        // const glidesQRFiscal = document.querySelector('.slide__qr-fiscal');
        // const glidesQRServicesALaPersonne = document.querySelector('.slide__qr-services-a-la-personne');
        // const glidesQRMarchesPublics = document.querySelector('.slide__qr-marches-publics');
        // const glidesQRMarchesPrives = document.querySelector('.slide__qr-marches-prives');
        // const glidesQREnvironnement = document.querySelector('.slide__qr-environnement');
        // const glidesQRAssurances = document.querySelector('.slide__qr-assurances');

        const configurationSlider = {
            type: 'carousel',
            gap: 15
        }

        if (glidesNewsRegion) {
            this.sliderNewsRegion(configurationSlider);
            this.createBullets(glidesNewsRegion);
        }

        if (glidesNewsFrance) {
            this.sliderNewsFrance(configurationSlider);
            this.createBullets(glidesNewsFrance);
        }

        if (glidesNewsWithoutTabs) {
            this.sliderNewsWithoutTabs(configurationSlider);
            this.createBullets(glidesNewsWithoutTabs);
        }

        if (glidesPresDeChezVous) {
            this.sliderPresDeChezVous(configurationSlider);
            this.createBullets(glidesPresDeChezVous);
        }

        if (glidesRealisations) {
            this.sliderRealisations(configurationSlider);
            this.createBullets(glidesRealisations);
        }

        if (glidesEntrepriseRecente) {
            this.sliderEntrepriseRecente(configurationSlider);
            this.createBullets(glidesEntrepriseRecente);
        }

        if (glidesLogoPartenaire) {
            const partnerCount = document.querySelectorAll('.logo-partenaire').length;
            if (partnerCount > 8) {
                this.sliderLogoPartenaire(configurationSlider);
                this.createBullets(glidesLogoPartenaire);
            }
        }

        if (glidesLecturesComplementaires) {
            this.sliderLecturesComplementaires(configurationSlider);
            this.createBullets(glidesLecturesComplementaires);
        }

        if (glidesQuestionReponse) {
            this.sliderQuestionReponse(configurationSlider);
            this.createBullets(glidesQuestionReponse);
        }

        // if (glidesQRSocial) {
        //     this.sliderQRSocial(configurationSlider);
        //     this.createBullets(glidesQRSocial);
        // }
        //
        // if (glidesQRFiscal) {
        //     this.sliderQRFiscal(configurationSlider);
        //     this.createBullets(glidesQRFiscal);
        // }
        //
        // if (glidesQRServicesALaPersonne) {
        //     this.sliderQRServicesALaPersonne(configurationSlider);
        //     this.createBullets(glidesQRServicesALaPersonne);
        // }
        //
        // if (glidesQRMarchesPublics) {
        //     this.sliderQRMarchesPublics(configurationSlider);
        //     this.createBullets(glidesQRMarchesPublics);
        // }
        //
        // if (glidesQRMarchesPrives) {
        //     this.sliderQRMarchesPrives(configurationSlider);
        //     this.createBullets(glidesQRMarchesPrives);
        // }
        //
        // if (glidesQREnvironnement) {
        //     this.sliderQREnvironnement(configurationSlider);
        //     this.createBullets(glidesQREnvironnement);
        // }
        //
        // if (glidesQRAssurances) {
        //     this.sliderQRAssurances(configurationSlider);
        //     this.createBullets(glidesQRAssurances);
        // }
    }

    sliderNewsRegion(configurationSlider) {
        new Glide('.slide__news--region', configurationSlider).mount();
    }

    sliderNewsFrance(configurationSlider) {
        new Glide('.slide__news--france', configurationSlider).mount();
    }

    sliderNewsWithoutTabs(configurationSlider) {
        new Glide('.slide__news--without-tabs', configurationSlider).mount();
    }

    sliderPresDeChezVous(configurationSlider) {
        new Glide('.slide__pres-de-chez-vous',
            {...configurationSlider, ...{
                    perView: 1,
                    peek: {
                        before: 0,
                        after: 346
                    },
                    breakpoints: {
                        1920: {
                            peek: {
                                before: 0,
                                after: 136
                            }
                        },
                        1366: {
                            peek: {
                                before: 0,
                                after: 212
                            }
                        },
                        1024: {
                            peek: {
                                before: 0,
                                after: 112
                            }
                        },
                        568: {
                            peek: {
                                before: 0,
                                after: 15
                            }
                        }
                    }
                }
            }).mount();
    }

    sliderRealisations(configurationSlider) {
        new Glide('.slide__realisation',
            {...configurationSlider, ...{
                    perView: 1,
                    peek: {
                        before: 0,
                        after: 346
                    },
                    breakpoints: {
                        1920: {
                            peek: {
                                before: 0,
                                after: 136
                            }
                        },
                        1366: {
                            peek: {
                                before: 0,
                                after: 212
                            }
                        },
                        1024: {
                            peek: {
                                before: 0,
                                after: 112
                            }
                        },
                        568: {
                            peek: {
                                before: 0,
                                after: 15
                            }
                        }
                    }
                }
            }).mount();
    }

    sliderLogoPartenaire(configurationSlider) {
        new Glide('.slide__logo-partenaire', {...configurationSlider, ...{perView: 8}}).mount();
    }

    sliderEntrepriseRecente(configurationSlider) {
        new Glide('.slide__entreprise-recente', {...configurationSlider, ...{perView: 5}}).mount();
    }

    sliderLecturesComplementaires(configurationSlider) {
        new Glide('.slide__lectures-complementaires',  {...configurationSlider, ...{
            perView: 3,
            type: 'slider',
            breakpoints: {
                1920: {
                    perView: 2
                },
                1024: {
                    perView: 1
                }
            }
        }}).mount();
    }

    sliderQuestionReponse(configurationSlider) {
        new Glide('.slide__question-reponse',  configurationSlider).mount();
    }

    // sliderQRSocial(configurationSlider) {
    //     new Glide('.slide__qr-social',  configurationSlider).mount();
    // }
    //
    // sliderQRFiscal(configurationSlider) {
    //     new Glide('.slide__qr-fiscal',  configurationSlider).mount();
    // }
    //
    // sliderQRServicesALaPersonne(configurationSlider) {
    //     new Glide('.slide__qr-services-a-la-personne',  configurationSlider).mount();
    // }
    //
    // sliderQRMarchesPublics(configurationSlider) {
    //     new Glide('.slide__qr-marches-publics',  configurationSlider).mount();
    // }
    //
    // sliderQRMarchesPrives(configurationSlider) {
    //     new Glide('.slide__qr-marches-prives',  configurationSlider).mount();
    // }
    //
    // sliderQREnvironnement(configurationSlider) {
    //     new Glide('.slide__qr-environnement',  configurationSlider).mount();
    // }
    //
    // sliderQRAssurances(configurationSlider) {
    //     new Glide('.slide__qr-assurances',  configurationSlider).mount();
    // }

    createBullets(el) {
        const countSlides = el.querySelectorAll('.glide__slides > *:not(.glide__slide--clone)').length;

        for (let i = 0; i < countSlides; i++) {
            el.querySelector('.glide__bullets').innerHTML += `<button class="glide__bullet" data-glide-dir="=${i}"></button>`;
        }
    }
}
