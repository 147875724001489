import 'selectize';
import AnimateHeight from './animateHeight';

export default class QuestionsReponses {
    constructor() {
        this.init();
    }

    init() {
        this.simulateSubmit();
        this.handleSeeMoreBtn();
    }

    simulateSubmit() {
        const filterForm = document.querySelector('form.qr-filter');

        const filterMonthSelect = $('.qr-filter-month-select');
        const filterThemeSelect = $('.qr-filter-theme-select');

        filterMonthSelect.selectize();
        filterThemeSelect.selectize();

        filterThemeSelect.on('change', function () {
            filterForm.submit();
        })

        filterMonthSelect.on('change', function () {
            filterForm.submit();
        })
    }

    handleSeeMoreBtn() {
        const seeMoreBtns = document.querySelectorAll('.see-more');

        if (seeMoreBtns) {
            seeMoreBtns.forEach(btn => {
                btn.addEventListener('click', () => {
                    const cardAnswer = btn.closest('.card-answer');
                    const answerExcerpt = cardAnswer.querySelector('.answer_detail_excerpt');
                    const answer = cardAnswer.querySelector('.answer_detail');

                    if (cardAnswer.getAttribute('data-collapsed') === 'true') {
                        cardAnswer.setAttribute('data-collapsed', 'false');
                        AnimateHeight.expandSection(answer);
                        AnimateHeight.collapseSection(answerExcerpt);
                    } else {
                        cardAnswer.setAttribute('data-collapsed', 'true');
                        AnimateHeight.collapseSection(answer);
                        AnimateHeight.expandSection(answerExcerpt);
                    }
                })
            })
        }
    }
}
