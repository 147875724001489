export default class Scroll {
    constructor() {
        this.init();
    }

    init() {
        this.scrollTo($('body#comment-adherer #go-to-subscription-form'));
    }

    // aTag a element w/ an anchor's link
    scrollTo(aTag) {
        aTag.on('click', function (event) {
            event.preventDefault();

            const link = aTag.attr('href'); // Cible
            const speed = 750; // Durée de l'animation (en ms)
            const top = Math.round($(link).offset().top - 140);

            $('html, body').animate({scrollTop: top}, speed); // Go

            return false;
        })

    }
}

