
export default class Print {
    constructor() {
        this.init();
    }

    init() {
        const btn = document.querySelector('.blk-print');

        if (btn) {
            btn.addEventListener('click', function (event) {
                window.print();
            });
        }
    }
}