import 'selectize';
import AnimateHeight from './animateHeight';

class CommonSearch {
    init() {
        this.resetAll();
        this.handleFilterDisplay();
    }

    resetAll() {
        const resetBtn = document.querySelector('.reset-all');

        if (resetBtn) {
            resetBtn.addEventListener('click', function () {
                window.location.href = window.location.href.split('?')[0];
            });
        }
    }

    handleFilterDisplay() {
        const filterBtn = document.querySelector('.filter-btn');

        if (filterBtn) {
            filterBtn.addEventListener('click', function () {

                const filter = document.querySelector('.nav-facets');
                const isCollapsed = filter.getAttribute('data-collapsed') === 'true';

                if (isCollapsed) {
                    AnimateHeight.expandSection(filter);
                    filter.setAttribute('data-collapsed', 'false');
                    filter.classList.add('box-shadow-4');
                    filterBtn.classList.remove('box-shadow-4');
                    filter.setAttribute('style', 'height:auto;');
                } else {
                    AnimateHeight.collapseSection(filter);
                    filter.classList.remove('box-shadow-4');
                    filterBtn.classList.add('box-shadow-4');
                }
            })
        }

    }
}
export default new CommonSearch();
