export default class Quiz {
    constructor() {
        this.init();
    }

    init() {
        this.addSectionsTitle();
    }

    addSectionsTitle() {
        const form = document.querySelector('#quizForm1');

        const formSection = document.querySelector('#quizForm1 .qsm-page');
        const formSection1 = document.querySelector('#quizForm1 .qsm-page-1');
        const formSection2 = document.querySelector('#quizForm1 .qsm-page-2');
        const formSection3 = document.querySelector('#quizForm1 .qsm-page-3');
        const formSection4 = document.querySelector('#quizForm1 .qsm-page-4');
        const formSection5 = document.querySelector('#quizForm1 .qsm-page-5');
        
        const titleQuizz = document.createElement('h2');
        titleQuizz.classList.add('quiz-title');

        const firstSubtitle1 = document.createElement('h3');
        firstSubtitle1.classList.add('first-subtitle');
        const firstSubtitle2 = document.createElement('h3');
        firstSubtitle2.classList.add('first-subtitle');
        const firstSubtitle3 = document.createElement('h3');
        firstSubtitle3.classList.add('first-subtitle');
        const firstSubtitle4 = document.createElement('h3');
        firstSubtitle4.classList.add('first-subtitle');

        const secondSubtitle = document.createElement('h3');
        secondSubtitle.classList.add('second-subtitle');

        const titleSection1 = document.createElement('h4');
        const titleSection2 = document.createElement('h4');
        const titleSection3 = document.createElement('h4');
        const titleSection4 = document.createElement('h4');

        titleQuizz.textContent = 'Quiz';
        firstSubtitle1.textContent = 'Testez vos pratiques'
        firstSubtitle2.textContent = 'Testez vos pratiques'
        firstSubtitle3.textContent = 'Testez vos pratiques'
        firstSubtitle4.textContent = 'Testez vos pratiques'
        secondSubtitle.textContent = 'Testez vos connaissances théoriques'

        titleSection1.textContent = '1/4 Gouvernance';
        titleSection2.textContent = '2/4 Gestion';
        titleSection3.textContent = '3/4 Commercial';
        titleSection4.textContent = '4/4 Production';

        if (form) {
            form.insertBefore(titleQuizz, formSection);
        }

        if (formSection1) {
            formSection1.insertBefore(firstSubtitle1, formSection1.firstChild);
            formSection1.insertBefore(titleSection1, formSection1.firstChild);
        }

        if (formSection2) {
            formSection2.insertBefore(firstSubtitle2, formSection2.firstChild);
            formSection2.insertBefore(titleSection2, formSection2.firstChild);
        }

        if (formSection3) {
            formSection3.insertBefore(firstSubtitle3, formSection3.firstChild);
            formSection3.insertBefore(titleSection3, formSection3.firstChild);
        }

        if (formSection4) {
            formSection4.insertBefore(firstSubtitle4, formSection4.firstChild);
            formSection4.insertBefore(titleSection4, formSection4.firstChild);
        }

        if (formSection5) {
            formSection5.insertBefore(secondSubtitle, formSection5.firstChild);
        }
    }
}
