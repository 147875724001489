class MenuArticle {
    constructor() {
        this.container = null;
        this.content = null;
        this.hasSubtitles = false;
        this.subtitles = [];

        this.content = document.querySelector('.companyText');

        if (!this.content) {
            this.content = document.querySelector('.freeText');
        }

        let menu = document.getElementById('nav-affix');

        if (this.content && menu) {
            this.checkSubtitles();

            let blockLeft = document.getElementById('list');
            if (blockLeft) {
                this.buildAffix(blockLeft);
            }

            let titles = this.content.getElementsByTagName('h2');

            if (titles.length == 0) {
                titles = this.content.getElementsByTagName('h3');
            }

            if (titles.length == 0) {
                let navTitle = menu.getElementsByClassName('nav-title')[0];

                navTitle.classList.add('hidden');
                blockLeft.classList.add('hidden');
            }

            window.addEventListener('scroll', () => {
                let winMeta = window.innerHeight / 2;
                let links = menu.querySelectorAll('.menu-item');

                for (let title of titles) {
                    let titleMeta = title.getBoundingClientRect();

                    if (titleMeta.top <= (winMeta + 150) && titleMeta.top >= (winMeta - 150)) {
                        let linkCurrent = menu.querySelector('[data-id="'+ title.id +'"]');

                        for (let link of links) {
                            link.classList.remove('hover');
                        }

                        linkCurrent.classList.add('hover');
                    }
                }

                /*let menuMeta = menu.getBoundingClientRect();
                let elements = this.content.querySelectorAll('.wp-block-quote');

                for (let element of elements) {
                    let elementMeta = element.getBoundingClientRect();

                    console.log(elementMeta.bottom, menuMeta.top);

                    if ((elementMeta.top >= menuMeta.top && elementMeta.top <= menuMeta.bottom) || (elementMeta.bottom >= menuMeta.top && elementMeta.bottom <= menuMeta.bottom) ) {
                        menu.classList.add('hidden');
                    } else {
                        menu.classList.remove('hidden');
                    }
                }*/

            });
        }

    }

    checkSubtitles()
    {
        let titles = this.content.getElementsByTagName('h2');

        if (titles.length == 0) {
            titles = this.content.getElementsByTagName('h3');
        }

        if (titles.length > 0) {
            this.hasSubtitles = true;
            this.subtitles = titles;

            for (let i = 0; i < titles.length; i++) {
                titles[i].setAttribute('id', this.normalize(titles[i].innerText));
            }
        }
    }

    buildAffix(blockLeft)
    {
        for (let i = 0; i < this.subtitles.length; i++) {
            let link = document.createElement('a');
            link.href = "#" + this.normalize(this.subtitles[i].innerText);
            link.setAttribute('data-id', this.normalize(this.subtitles[i].innerText));
            link.innerText = this.subtitles[i].innerText;
            link.classList.add('menu-item');

            blockLeft.append(link);

            link.addEventListener('click', (event) => {
                event.preventDefault();

                let itemId = link.getAttribute('data-id');
                let title = document.getElementById(itemId);

                title.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            });
        }

        let affix = document.getElementById('nav-affix');
        let bodyRect = document.body.getBoundingClientRect(),
            elemRect = affix.getBoundingClientRect(),
            offset   = elemRect.top - 130 - bodyRect.top;


        window.onscroll = () => this.scroll(affix, offset);
    }

    scroll(affix, offset) {
        let scrollTop = window.pageYOffset;

        if (scrollTop > offset) {
            affix.classList.add('affix');
        } else {
            affix.classList.remove('affix');
        }
    }

    normalize(source) {
        return source.replace(/^[^a-z]+|[^\w:.-]+/gi, "");
    }

}

export default MenuArticle;
