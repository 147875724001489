class Newsletter {

    init() {
        const saveButton = document.querySelector('.is-subscriber-save-btn');

        if (saveButton) {
            saveButton.addEventListener('click', (e) => {
                const newsletterChoice = document.querySelector('#rgpd_newsletters_week');

                if (!newsletterChoice.checked) {
                    e.preventDefault();
                    const cancelButton = document.querySelector('.modal-cancel');

                    const modal = $('#save-subscriber-choice');
                    modal.modal();

                    if (cancelButton) {
                        cancelButton.addEventListener('click', (e) => {
                            modal.modal('hide');
                        });
                    }
                }
            });
        }
    }
}

export default new Newsletter;
