import { get } from './components/api';

document.addEventListener("DOMContentLoaded", async (event) => {
    let searchFields = document.querySelectorAll('.search-field');

    if(searchFields.length){
        searchFields.forEach((searchField) => {
            let container = searchField.parentNode.parentNode;
            let list = container.querySelector('.ir-autosuggest');
            let timeoutId = null;

            searchField.addEventListener('input', (e) => {
                if(timeoutId){
                    clearTimeout(timeoutId);
                }

                timeoutId = search(e, timeoutId);
            })

            searchField.addEventListener('blur', (e) => {
                setTimeout(() => {
                    list.style.display = 'none';
                }, 500);
            })

            searchField.addEventListener('focus', (e) => {
                list.style.display = 'block';
            })
        })
    }

});

function search(e){
    let q = e.target.value;
    let timeoutId = null;

    if(q.length % 3 === 0){
        getSuggestions(q, e.target);
    } else {
        timeoutId = setTimeout(() => {
            getSuggestions(q, e.target);
        }, 500);
    }

    return timeoutId;
}

async function getHtmlDocuments(q){
    const per_page = 5;
    const documents = await get('search/posts-document-database', {q, per_page});
    const files = await get('search/documents', {q, is_documentary: 'true'});

    let results = 0;
    if(documents){
        results += documents.found;
    }

    if(files){
        results += files.found;
    }

    let html = '<ul class="ir-autosuggest-list">';

    if(results){
        if(documents && documents.hasOwnProperty('rawResults') && documents.rawResults.length){
            html += '<li class="group-title">Base documentaire</li>';
            documents.rawResults.forEach((d, i) => {
                html += '<li class="group-item"><a href="'+documents.results[i].route+'" target="_blank">'+d.highlight.name.snippet+'</a></li>';
            });
        }

        if(files && files.hasOwnProperty('rawResults') && files.rawResults.length){
            html += '<li class="group-title">Fichiers</li>';
            files.rawResults.forEach((f, i) => {
                html += '<li class="group-item"><a href="'+files.results[i].route+'" target="_blank">'+f.highlight.name.snippet+'</a></li>';
            });
        }

        html += '<li class="group-submit"><button type="submit">Voir tout les résultats ('+results+')</button></li>';
    } else {
        html += '<p> Aucun résultat. </p>';
    }

    html += '</ul>';

    return html;
}

async function getHtmlServices(q){
    const per_page = 5;
    const services = await get('search/posts-service', {q, per_page});

    let results = 0;
    if(services){
        results = services.found;
    }

    let html = '<ul class="ir-autosuggest-list">';

    if(results){
        if(services && services.hasOwnProperty('rawResults') && services.rawResults.length){
            services.rawResults.forEach((s, i) => {
                html += '<li class="group-item"><a href="'+services.results[i].route+'" target="_blank">'+s.highlight.name.snippet+'</a></li>';
            });

            html += '<li class="group-submit"><button type="submit">Voir tout les résultats ('+results+')</button></li>';
        }
    } else {
        html += '<p> Aucun résultat. </p>';
    }

    html += '</ul>';

    return html;
}

async function getHtmlGlocal(q){
    const per_page = 5;
    const documents = await get('search/posts-document-database', {q, per_page});
    const pages = await get('search/pages', {q, per_page});
    const posts = await get('search/posts', {q, per_page});

    let results = 0;
    if(documents){
        results += documents.found;
    }

    if(pages){
        results += pages.found;
    }

    if(posts){
        results += posts.found;
    }

    let html = '<ul class="ir-autosuggest-list">';

    if(results){
        if(documents && documents.hasOwnProperty('rawResults') && documents.rawResults.length){
            html += '<li class="group-title">Base documentaire</li>';
            documents.rawResults.forEach((d, i) => {
                html += '<li class="group-item"><a href="'+documents.results[i].route+'" target="_blank">'+d.highlight.name.snippet+'</a></li>';
            });
        }

        if(pages && pages.hasOwnProperty('rawResults') && pages.rawResults.length){
            html += '<li class="group-title">Pages</li>';
            pages.rawResults.forEach((pa, i)=> {
                html += '<li class="group-item"><a href="'+pages.results[i].route+'" target="_blank">'+pa.highlight.name.snippet+'</a></li>';
            });
        }

        if(posts && posts.hasOwnProperty('rawResults') && posts.rawResults.length){
            html += '<li class="group-title">Actualités</li>';
            posts.rawResults.forEach((po, i) => {
                html += '<li class="group-item"><a href="'+posts.results[i].route+'" target="_blank">'+po.highlight.name.snippet+'</a></li>';
            });
        }

        html += '<li class="group-submit"><button type="submit">Voir tout les résultats ('+results+')</button></li>';
    } else {
        html += '<p> Aucun résultat. </p>';
    }

    html += '</ul>';

    return html;
}

async function getSuggestions(q, input) {

    let html = '';

    if(window.location.href.includes('explorer-les-services') || window.location.href.includes('unep-services')){
        html = await getHtmlServices(q);
    } else {
        if(window.location.href.includes('explorer-la-base-documentaire') || window.location.href.includes('base-documentaire')){
            html = await getHtmlDocuments(q);
        } else {
            html = await getHtmlGlocal(q);
        }
    }

    let container = input.parentNode.parentNode;
    let list = container.querySelector('.ir-autosuggest');

    list.style.display = 'block';

    list.innerHTML = html;
}
