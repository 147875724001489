import 'selectize';

const preSubscription = () => {
    const selectType = document.querySelector('#establishment');
    const selectSimulationType = document.querySelector('#etablissement_simulation');
    const simulationCA = document.querySelector('.simulation-ca');
    const cA = document.querySelector('.ca');

    if (selectSimulationType && simulationCA) {
        let $select = $('#etablissement_simulation').selectize({
            onChange: (event) => {
                if (event == '575') {
                    simulationCA.classList.add('d-none');
                } else {
                    simulationCA.classList.remove('d-none');
                }
            },
        });
    }

    if (selectType && cA) {
        let $selectType= $('#establishment').selectize({
            onChange: (event) => {
                if (event == '575') {
                    cA.classList.add('d-none');
                } else {
                    cA.classList.remove('d-none');
                }
            },
        });
    }
}

export default preSubscription;
