import axios from 'axios';

class Subscription {
    constructor() {
        this.submit = document.getElementById('subscription-submit');
        this.modalcore = document.getElementById('modal-core');
        this.forms = document.getElementsByClassName('form');
        this.previousText = '';
        this.holdingResponse = true;
        this.intervalId = null;
        this.submitPayment = document.getElementById('payment-submit');
        this.submitInvoiceChoice = document.getElementById('facturation-submit');
        this.createSubsciptionForm = document.getElementById('subscription_creation');
    }

    init() {
        $( ".changeInput" ).on("keyup change",function() {
            /*if($(this).attr('name') == "company_revenues"){
                if($(this).val().replace(/\s/g,'') < 1){
                    $(this).css('color', 'red');
                    $('.errormsg').css('display', 'block');
                }else{
                    $(this).css('color', '#004855');
                    $('.errormsg').css('display', 'none');
                }
            }*/
            $('#submitForm').prop('disabled', false);
            $('.changeInput').each(function(){
                if($(this).val() == ""){
                    $('#submitForm').prop('disabled', true);
                }
                /*if($(this).attr('name') == "company_revenues" && $(this).val().replace(/\s/g,'') < 1){
                    $('#submitForm').prop('disabled', true);
                }*/
            })
        });

        if (this.submitInvoiceChoice) {
            this.submitInvoiceChoice.addEventListener('click', (event) => {
                let form = document.getElementById('subscription-facturation-choice');
                form.submit();
            });
        }

        if (this.submitPayment) {
            this.submitPayment.addEventListener('click', (event) => {
                this.previousText = this.submitPayment.innerText;
                this.submitPayment.querySelector('.btn-text').innerText = 'Traitement en cours...';
                // this.submitPayment.setAttribute('disabled', 'true');
                this.submitPayment.classList.toggle('disabled');
                const spinner = this.submitPayment.querySelector('.spinner-border');
                spinner.classList.toggle('hidden');
            });
        }

        if (this.submit) {
            Array.prototype.filter.call(this.forms, (form) => {
                if (form.classList.contains('holding')) {
                    this.holdingResponse = false;
                }
            });

            this.submit.addEventListener('click', (event) => {
                event.preventDefault();

                const alert = document.querySelector('.alert-revenues');
                if (alert) {
                    alert.style.display = 'none';
                }

                const companiesList = document.getElementById('list-companies-revenues');
                if (companiesList) {
                    companiesList.style.display = 'none';
                }

                if (!this.submit.classList.contains('disabled') && !this.submit.classList.contains('success') && !this.submit.classList.contains('error')) {
                    let formsValidated = true;

                    Array.prototype.filter.call(this.forms, (form) => {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            formsValidated = false;
                        }

                        form.classList.add('was-validated');
                    });

                    if (formsValidated) {
                        this.previousText = this.submit.querySelector('.btn-text').innerText;
                        this.submit.querySelector('.btn-text').innerText = 'Envoi des données en cours...';
                        this.submit.setAttribute('disabled', 'true');
                        this.submit.classList.toggle('disabled');
                        document.querySelector('#modal-core').innerHTML = '';

                        // const spinner = this.submit.querySelector('.spinner-border');
                        // spinner.classList.toggle('hidden');

                        document.querySelector('#spinnerModal').classList.remove('hidden');

                        const returnBtn = document.querySelector('#subscription-cancel');
                        returnBtn.classList.add('d-none');

                        Array.prototype.filter.call(this.forms, (form) => {
                            this.disableForm(form);
                        });

                        Array.prototype.filter.call(this.forms, (form) => {
                            if (form.classList.contains('holding') && !this.holdingResponse) {
                                this.submitForm(form);
                            }
                        });

                        this.intervalId = setInterval(() => {
                            if (this.holdingResponse) {
                                clearInterval(this.intervalId);

                                Array.prototype.filter.call(this.forms, (form) => {
                                    if (!form.classList.contains('holding')) {
                                        setTimeout(() => {
                                            this.submitForm(form);
                                        }, 50);
                                    }
                                });
                            }
                        }, 500);
                    }
                }

                // if (!this.submit.classList.contains('disabled') && this.submit.classList.contains('success')) {
                //     this.submit.querySelector('.btn-text').innerText = "Génération de l'adhésion...";
                //     this.submit.setAttribute('disabled', 'true');
                //     this.submit.classList.toggle('disabled');
                //
                //     const spinner = this.submit.querySelector('.spinner-border');
                //     spinner.classList.toggle('hidden');
                //     const form = this.submit.closest('.final');
                //     this.submitFinalForm(form);
                // }
            });
        }

        const toPrintBtn = document.getElementById('printpdf');

        if (toPrintBtn) {
            toPrintBtn.addEventListener('click', (event) => {
                event.preventDefault();
                this.print();
            });
        }

        this.onOpenModal();
    }

    submitForm(form) {
        let formData = this.getFormData(form);
        let holding = form.classList.contains('holding');

        axios
            .post(form.getAttribute('action'), formData)
            .then((response) => {
                form.classList.add('returnedTrue');
                this.validateForm(form);
            })
            .catch((error) => {
                console.log(error.response.data.error);
                form.classList.add('returnedFalse');
                this.invalidForm(form);
            })
            .finally(() => {
                this.checkAllForm();

                if (holding) {
                    this.holdingResponse = true;
                }
            })
        ;
    }

    submitFinalForm(form) {
        let formData = this.getFormData(form);
        axios
            .post(form.getAttribute('action'), formData)
            .then((response) => {
                console.log(response);
                document.location.href = '/espace-adherent/renouvellement-en-ligne/recapitulatif';
            })
            .catch((error) => {
                console.log(error.response);
            });
        ;
    }

    checkAllForm() {
        let allFormAsValidated = true;
        let errored = false;

        Array.prototype.filter.call(this.forms, (form) => {
            if (!form.classList.contains('returnedTrue')) {
                allFormAsValidated = false;
            }

            if (form.classList.contains('returnedFalse')) {
                errored = true;
            }
        });

        if (allFormAsValidated) {
            this.submit.classList.toggle('disabled');
            this.submit.removeAttribute('disabled');
            this.submit.querySelector('.btn-text').innerText = 'Voir le récapitulatif';
            this.submit.classList.add('success');

            // const spinner = this.submit.querySelector('.spinner-border');
            // spinner.classList.add('hidden');

            document.querySelector('#spinnerModal').classList.add('hidden');
            document.querySelector('#modal-core').innerHTML = 'Merci de ces informations. En complément du calcul de votre cotisation, celles-ci sont nécessaires pour affirmer la représentativité patronale de l’Unep.';

            // auto generate subscription
            this.submit.querySelector('.btn-text').innerText = "Génération de l'adhésion...";
            this.submit.setAttribute('disabled', 'true');
            this.submit.classList.toggle('disabled');

            const spinner = this.submit.querySelector('.spinner-border');
            spinner.classList.toggle('hidden');

            this.submitFinalForm(this.createSubsciptionForm);
        }

        if (errored) {
            this.submit.querySelector('.btn-text').innerText = this.previousText;
            // this.submit.classList.remove('disabled');
            // this.submit.removeAttribute('disabled');

            const spinner = this.submit.querySelector('.spinner-border');
            spinner.classList.add('hidden');

            const message = document.querySelector('#eudonet-error');
            message.classList.remove('hidden');
        }
    }

    getFormData(form) {
        let array = this.serializeArray(form);
        let formData = new FormData();

        for (let i = 0; i < array.length; i++) {
            formData.append(array[i].name, array[i].value);
        }

        return formData;
    }

    serializeArray(form) {
        // Setup our serialized data
        var serialized = [];

        // Loop through each field in the form
        for (var i = 0; i < form.elements.length; i++) {

            var field = form.elements[i];

            // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            // If a multi-select, get all selections
            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push({
                        name: field.name,
                        value: field.options[n].value
                    });
                }
            }

            // Convert field data to a query string
            else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push({
                    name: field.name,
                    value: field.value
                });
            }
        }

        return serialized;
    }

    disableForm(form) {
        form.classList.add('disabled');

        const inputs = form.getElementsByTagName('input');
        Array.prototype.filter.call(inputs, function(input) {
            input.setAttribute('readonly', true)
        });
    }

    validateForm(form) {
        form.classList.add('was-validated');

        const inputs = form.getElementsByTagName('input');
        Array.prototype.filter.call(inputs, function(input) {
            input.classList.add('is-valid')
        });
    }

    invalidForm(form) {
        form.classList.add('was-validated');

        const inputs = form.getElementsByTagName('input');
        Array.prototype.filter.call(inputs, function(input) {
            input.classList.add('is-invalid')
        });
    }

    print() {
        const container = document.getElementById('element-to-print');
        const css = document.getElementById('all-css');

        const html = '<html><head>'+
            '<link href="'+ css.getAttribute("href") +'" rel="stylesheet" type="text/css" />'+
            '</head>' +
            '<body style="background:#ffffff;padding-top: 100px!important;">' +
            container.innerHTML +
            ' <script type="text/javascript">\n' +
            '      window.onload = function() { window.print(); }\n' +
            ' </script></body></html>';

        const WindowObject = window.open("", "PrintWindow",
            "width=750,height=890,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes");

        WindowObject.document.writeln(html);
        WindowObject.focus();

        WindowObject.addEventListener('load', () => {
            WindowObject.print();
        });
    }

    onOpenModal() {
        const body = document.querySelector('body');
        if (body) {
            const observer = new MutationObserver((mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        if (mutation.target.classList.contains('modal-open') && body.id === 'renouvellement-dadhesion') {

                            const forms = document.querySelectorAll('form');
                            if (forms) {
                                const alert = document.querySelector('.alert-revenues');
                                const card = document.getElementById('list-companies-revenues');
                                alert.style.display = 'none';
                                card.innerHTML = '';

                                const companiesAlertRevenues = [];
                                for (const form of forms) {
                                    if (form.id.includes('subscription_')) {
                                        const inputs = form.elements;
                                        if (inputs['company_old_revenues'] && inputs['company_revenues']) {
                                            const companyRevenues = inputs['company_revenues'].value.split(" ").join("");
                                            const companyOldRevenues = inputs['company_old_revenues'].value;
                                            console.log(companyRevenues, companyOldRevenues);
                                            var result = (companyOldRevenues - (companyOldRevenues * (20 / 100)));
                                            if (result > companyRevenues) {
                                                companiesAlertRevenues.push(inputs['company_name'].value);
                                            }
                                        }
                                    }
                                }

                                if (companiesAlertRevenues.length > 0) {
                                    if (card && alert) {
                                        alert.style.display = 'block';
                                        for (const company of companiesAlertRevenues) {
                                            const span = document.createElement('span');
                                            span.innerText = company;
                                            card.append(span);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });

            observer.observe(body, {
                attributes: true
            });
        }
    }
}

export default new Subscription();
