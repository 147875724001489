class Login {
    constructor() {
        this.forms = document.getElementsByName('login');
        this.messages = document.querySelectorAll('.tml-error');
    }

    init() {
        if (this.forms) {
            this.password();

            this.links = document.querySelector('.tml-links');

            if (this.links) {
                Array.prototype.filter.call(this.forms, (form) => {
                    const fields = form.querySelectorAll('.form-group');
                    const submit = fields[fields.length - 1];

                    this.links.classList.add('mb-5');

                    submit.before(this.links);
                });
            }
        }
    }

    password() {
        const password = document.getElementById("user_pass");

        if (password) {
            const passwordContainer = password.parentNode;
            passwordContainer.classList.add('password-block');
            passwordContainer.classList.add('visible');

            const inputContainer = document.createElement('div');
            inputContainer.classList.add('input-group');


            const btn = document.createElement('span');
            btn.classList.add('btn-visibility');

            inputContainer.appendChild(password);
            inputContainer.appendChild(btn);
            passwordContainer.appendChild(inputContainer);


            btn.addEventListener('click', (event) => {
                event.preventDefault();

                this.togglePasswordVisibility(password);
            });
        }
    }

    togglePasswordVisibility(element) {
        const parentBlock = element.closest('.input-group');

        if (element.type === "password") {
            element.type = "text";
            parentBlock.classList.add('visible');
        } else {
            element.type = "password";
            parentBlock.classList.remove('visible');
        }
    }
}

export default new Login();
