
export default class Search {
    constructor() {
        this.init();
    }

    init() {
        const btn = document.querySelector('.btn-popup-search-open');
        const popup = document.querySelector('.popup-search');
        const btnClose = document.querySelector('.popup-search .btn-popup-close');

        if (btn) {
            btn.addEventListener('click', function (event) {
                btn.classList.toggle('active');
                popup.classList.toggle('open');
                const input = document.querySelector('#big-search');

                if(popup.classList.contains('open')){
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }

                if (input) {
                    input.focus();
                }
            });
        }

        if (btnClose) {
            btnClose.addEventListener('click', function (event) {
                btn.classList.toggle('active');
                popup.classList.toggle('open');
                if(popup.classList.contains('open')){
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            });
        }
    }
}
