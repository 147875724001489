import axios from "axios";
import { SimpleDropzone } from 'simple-dropzone/dist/simple-dropzone.module';

const mandateChoiceSelection = () => {
    const radiosNewMandate = document.querySelectorAll('.mc-new');
    const radiosCurrentMandate = document.querySelectorAll('.mc-current');
    const newMandateButtons = document.querySelectorAll('.new-mandate-submit');
    const currentMandateButtons = document.querySelectorAll('.current-mandate-submit');

    if (radiosNewMandate && radiosCurrentMandate) {

        radiosNewMandate.forEach((radioNewMandate) => {
            radioNewMandate.addEventListener('click', function() {
                if (radioNewMandate.checked) {
                    const parent = radioNewMandate.closest('.tabform');

                    if (parent) {
                        const formNewMandate = parent.querySelector('.accordion_payment_collapse_mandate_new');
                        const currentMandate = parent.querySelector('.accordion_payment_collapse_mandate');

                        if (formNewMandate && currentMandate) {
                            formNewMandate.classList.remove('d-none');
                            currentMandate.classList.add('d-none');
                        }
                    }
                }
            });
        });

        radiosCurrentMandate.forEach((radioCurrentMandate) => {
            radioCurrentMandate.addEventListener('click', function() {
                if (radioCurrentMandate.checked) {
                    const parent = radioCurrentMandate.closest('.tabform');

                    if (parent) {
                        const formNewMandate = parent.querySelector('.accordion_payment_collapse_mandate_new');
                        const currentMandate = parent.querySelector('.accordion_payment_collapse_mandate');

                        if (formNewMandate && currentMandate) {
                            formNewMandate.classList.add('d-none');
                            currentMandate.classList.remove('d-none');
                        }
                    }
                }
            });
        });
    }

    if (newMandateButtons) {
        newMandateButtons.forEach((button) => {
            button.addEventListener('click', formNewMandateSubmit)
        });
    }

    if (currentMandateButtons) {
        currentMandateButtons.forEach((button) => {
            button.addEventListener('click', formCurrentMandateSubmit)
        });
    }
}

const formCurrentMandateSubmit = (event) => {
    event.preventDefault();

    const form = event.target.closest('form');
    const loading = form.querySelector('.spinner-border');

    if (form) {
        loading.classList.remove('hidden');
        event.target.setAttribute('disabled', 'true');
        event.target.classList.add('disabled');
        submitCurrentMandateForm(form, loading, event.target);
    }
}

const submitCurrentMandateForm = (form, loading, button) => {

    let formData = getFormData(form);
    axios
        .post(form.getAttribute('action'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.status === 200) {
                location.reload();
            }

            loading.classList.add('hidden');
            button.setAttribute('disabled', 'false');
            button.classList.remove('disabled');
        })
        .catch((error) => {
            console.log(error);
            loading.classList.add('hidden');
            button.removeAttribute('disabled');
            button.classList.remove('disabled');
        });
}

const formNewMandateSubmit = (event) => {
    event.preventDefault();

    const form = event.target.closest('form');
    const feedbacks = form.querySelectorAll('.invalid-feedback');
    const inputs = form.querySelectorAll('.form-control');
    const loading = form.querySelector('.spinner-border');

    if (feedbacks) {
        feedbacks.forEach((element) => {
            element.remove();
        });
    }

    if (inputs) {
        inputs.forEach((element) => {
            element.classList.remove('is-valid');
            element.classList.remove('is-invalid');
        });
    }

    if (form) {
        loading.classList.remove('hidden');
        event.target.setAttribute('disabled', 'true');
        event.target.classList.add('disabled');
        submitMandateForm(form, loading, event.target);
    }
}

const submitMandateForm = (form, loading, button) => {

    let formData = getFormData(form);
    const inputEl = form.querySelector('.input-dropzone');

    formData.append('file', inputEl.files[0]);

    axios
        .post(form.getAttribute('action'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.status === 200) {
                location.reload();
            }

            loading.classList.add('hidden');
            button.setAttribute('disabled', 'false');
            button.classList.remove('disabled');
        })
        .catch((error) => {
            if (error.response) {
                let errors = error.response.data.error;

                for (let i=0; i < errors.length; i++) {
                    let error = document.createElement('div');
                    error.classList.add('invalid-feedback');
                    error.innerText = errors[i]['message'];

                    let element = form.querySelector('.' + errors[i]['type']);

                    if (element && error) {
                        element.classList.add('is-invalid');
                        insertAfter(element, error);
                    }
                }
            }

            loading.classList.add('hidden');
            button.removeAttribute('disabled');
            button.classList.remove('disabled');
        });
}

const getFormData = (form) => {
    let array = serializeArray(form);
    let formData = new FormData();

    for (let i = 0; i < array.length; i++) {
        formData.append(array[i].name, array[i].value);
    }

    return formData;
}

const serializeArray = (form) => {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i];

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

        // Convert field data to a query string
        else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push({
                name: field.name,
                value: field.value
            });
        }
    }

    return serialized;
}

const insertAfter = (referenceNode, newNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

const mandateSignatureClick = () => {
    const signature = document.querySelector('.sign-btn');

    if (signature) {
        signature.addEventListener('click', mandateSignatureCheck);
    }
}

const mandateSignatureCheck = (event) => {
    const loading = event.target.querySelector('.spinner-border');
    const wrapper = event.target.closest('.sign-wrapper');
    const yousign = wrapper.querySelector('.yousign-wrapper');

    if (yousign) {
        event.preventDefault();

        const iframe = '<iframe id="yousignSignatureProcedure"\n' +
            '    title="Procédure de signature de mandat"\n' +
            '    width="100%"\n' +
            '    height="700px"\n' +
            '    src='+ event.target.href +'>\n' +
            '</iframe>'

        yousign.innerHTML = iframe;
        yousign.classList.remove('d-none');
        event.target.setAttribute('disabled', 'true');
        event.target.classList.add('disabled');
        event.target.classList.add('d-none');

    } else {
        loading.classList.remove('hidden');
        event.target.setAttribute('disabled', 'true');
        event.target.classList.add('disabled');
    }

    const checker = setInterval(function () {
        axios
            .post('/yousign/manual/status', {
                procedure: event.target.dataset.id
            })
            .then(function (response) {
                if (response.status === 200 && response.data.message === 'done') {
                    clearInterval(checker);
                    setTimeout(function () {
                        location.reload();
                    }, 1500)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, 4000);
}

const mandateCollection = () => {
    mandateChoiceSelection();
    mandateSignatureClick();

    const wrapper = document.querySelectorAll('.dropzone-wrapper');

    if (wrapper) {
        wrapper.forEach((element) => {
            const dropEl = element.querySelector('.dropzone');
            const inputEl = element.querySelector('.input-dropzone');
            const span = dropEl.querySelector('.label-text');

            if (dropEl && inputEl) {
                const dropzone = new SimpleDropzone(dropEl, inputEl);

                dropzone.on('drop', ({files}) => {
                    files = Array.from(files);

                    if (files[0]) {
                        span.innerHTML = files[0][0];
                    } else {
                        span.innerHTML = 'Merci de déposer votre RIB au format PDF.';
                    }
                });
            }
        });
    }

}

export default mandateCollection;
