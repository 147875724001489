//Pour les iframes youtube avec un image/bouton play, au clic sur le bouton :
//Masquer l'image, afficher l'iframe et lancer la vidéo
class YoutubeVideoIframe {
  constructor() {
    this.init();
  }

  init() {
    if (document.querySelector('.js-trigger-youtube-play') === null) {
      return 0;
    }

    document.querySelectorAll('.js-trigger-youtube-play').forEach(function (figure) {
      figure.addEventListener('click', function (event) {
        let iframe = figure.parentElement.querySelector('iframe');
        figure.classList.add('d-none');
        iframe.classList.remove('d-none');
        iframe.src = iframe.src + '?autoplay=1';
      });
    });
  }
}

export default YoutubeVideoIframe;
