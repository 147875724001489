export default class Localiser {
    constructor() {
        this.init();
    }

    init() {
        this.initRange();
        this.initLocalisation();
        this.scrollMap();
        this.navTabs();
        this.openParam();
    }

    initRange() {
        const range = document.getElementById('range');
        const graduations = document.querySelectorAll('.range-graduations li');

        if (range) {
            range.addEventListener('change', function(event) {
                changeRange(null, range);
            });

            for (let i = 0; i < graduations.length; i++) {
                graduations[i].addEventListener('click', function(event) {
                    let rangeSelected = this;
                    range.value = rangeSelected.dataset.range;

                    changeRange(rangeSelected, range);
                });
            }
        }

        function changeRange(rangeSelected = null, range) {
            const rangeCurrent = document.querySelector('.range-graduations li.active');

            if (rangeSelected === null) {
                rangeSelected = document.querySelector('.range-' + range.value);
            }

            rangeSelected.classList.add('active');
            rangeCurrent.classList.remove('active');
        }
    }

    initLocalisation() {
        const btnLocalisation = document.getElementById('btn-localisation');

        if (btnLocalisation) {
            let proto = window.location.protocol;

            if (proto === 'https:') {
                let options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };

                btnLocalisation.addEventListener('click', function (event) {
                    event.preventDefault();
                    navigator.geolocation.getCurrentPosition( success, error , options);
                });
            } else {
                btnLocalisation.setAttribute('disabled', 'disabled');
            }
        }

        function success(pos) {
            let crd = pos.coords;

            /*console.log(pos);

            console.log('Votre position actuelle est :');
            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude : ${crd.longitude}`);
            console.log(`La précision est de ${crd.accuracy} mètres.`);*/

            window.location.href = `/localiser-un-paysagiste?userlat=${crd.latitude}&userlng=${crd.longitude}`;
        }

        function error(err) {
            console.warn(`ERREUR (${err.code}): ${err.message}`);
        }
    }

    scrollMap() {
        const footer = document.querySelector('footer');
        const map = document.querySelector('.gm-map');
        const navTabs = document.querySelectorAll('#nav-tab-menu-location .nav-link');

        if (map) {
            window.addEventListener('scroll', () => {
                if (window.matchMedia("(min-width: 768px)").matches) {
                    let footerMeta = footer.getBoundingClientRect();
                    let winHeight = window.innerHeight;

                    if (footerMeta.top <= winHeight) {
                        map.classList.add('no-fixed');
                    } else {
                        map.classList.remove('no-fixed');
                    }
                }
            });

            if (navTabs) {
                for (const navTab of navTabs) {
                    navTab.addEventListener('click', function (event) {
                        if (map.classList.contains('no-fixed')) {
                            map.classList.remove('no-fixed');
                        }
                    })
                }
            }
        }
    }

    navTabs() {
        const btnTabs = document.querySelectorAll('#nav-tab-menu-location .nav-link');

        if (btnTabs) {
            for (const btn of btnTabs) {
                btn.addEventListener('click', function (event) {
                    event.preventDefault();

                    const tabSelect = document.querySelector('#nav-tab-menu-location .nav-link.active');
                    const formSelected = document.getElementById(btn.dataset.form);
                    const forms = document.querySelectorAll('#form-name, #form-search');

                    tabSelect.classList.remove('active');
                    btn.classList.add('active');

                    for (const form of forms) {
                        form.classList.add('hidden');
                    }

                    formSelected.classList.remove('hidden');
                })
            }
        }
    }

    openParam() {
        const blk = document.querySelector('.page-localiser-un-paysagiste .blk-search');
        const btn = document.querySelector('.page-localiser-un-paysagiste .blk-search .btn-open-param');

        if (btn) {
            btn.addEventListener('click', function (event) {
                blk.classList.toggle('open');
            })
        }
    }

}
