import Forms from './components/forms';
import axios from "axios";
import 'selectize';

class ExtranetForms {
    init() {
        const userForm = document.getElementById('adduser');
        const uploadButtons = document.querySelectorAll('.upload-button');
        const toggleBtns = document.querySelectorAll('.btn-toggle');

        const url = new URL(window.location.href);
        const defaultTab = url.searchParams.get("tab");

        if (defaultTab) {
            $('#nav-tab a[href="#' +defaultTab+ '"]').tab('show');
        }

        if (uploadButtons) {
            Array.prototype.filter.call(uploadButtons, (btn) => {
                const parent = btn.closest('.picture-wrapper');
                const fileInput = parent.querySelector('.file-upload');
                const img = parent.querySelector('.profile-pic');

                btn.addEventListener('click', () => {
                    Forms.simulateClick(fileInput);
                });

                fileInput.addEventListener('change', (event) => {
                    Forms.readURL(event.target, img);
                });
            });
        }

        if (userForm) {
            Forms.passwordInput(document.getElementById('password1'));
            Forms.passwordInput(document.getElementById('password2'))
        }

        if (toggleBtns) {
            const wpeditors = document.querySelectorAll('.wp-editor-wrap');
            Array.prototype.filter.call(wpeditors, (wpeditor) => {
                wpeditor.classList.add('d-none');
            });

            Array.prototype.filter.call(toggleBtns, (btn) => {
                btn.addEventListener('click', (event) => {
                    Forms.toggleCardForm(event.target.closest('.card'), btn);
                });
            });
        }

        const individualInput = document.querySelector('#individual');
        const publicInput = document.querySelector('#public');
        const privateInput = document.querySelector('#private');

        if (individualInput && publicInput && privateInput && total) {
            this.cA(individualInput, publicInput, privateInput, total);
        }

        $('.cap_lvl_1').on('change', function() {
            let $value = $(this).val();
            const $row = $(this).closest('.row');
            const $selectorLvl2 = $row.find('.cap_lvl_2');

            axios
                .get('/wp-json/wp/v2/company_skills?parent=' + $value)
                .then((response) => {
                    $selectorLvl2[0].selectize.clear(true);
                    $selectorLvl2[0].selectize.clearOptions();

                    for (let i = 0; i < response.data.length; i++) {
                        let name = $('<textarea />').html(response.data[i].name).text();

                        $selectorLvl2[0].selectize.addOption({
                            value: response.data[i].id,
                            text: name.charAt(0).toUpperCase() + name.slice(1)
                        });
                    }
                });
        });

        $('.remove-skill').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });

        $('.remove-qualipaysage').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.container-forms-qualipaysage').remove();
        });

        const addSkill = document.querySelector('#add_skill');

        if (addSkill) {
            addSkill.addEventListener('click', (e) => {
                const skills = JSON.parse(e.target.dataset.skills);
                this.createSkillRow(skills);
            });
        }

        const addQpSkill = document.querySelector('#add_qp_skill');

        if (addQpSkill) {
            addQpSkill.addEventListener('click', (e) => {
                const data = JSON.parse(e.target.dataset.qpskills);
                var skills = data.company_qp_skills;
                var status = data.company_qp_status;
                var authorizations = data.company_qp_authorizations;
                var labels = data.company_qp_labels;

                this.createQpSkillRow(skills, status, authorizations, labels);
            });
        }

        jQuery('#update_access').on('change', () => {
            this.handleAccessibleContent();
        });
        jQuery('#invite_access').on('change', () => {
            this.handleAccessibleContent();
        });

        jQuery('.edit-user').on('click', (event) => {
            let userName = event.target.getAttribute('data-username');
            if (userName == null) {
                userName = event.target.parentElement.getAttribute('data-username');
            }
            this.getUserData(userName);
        });

        jQuery('.delete-user').on('click', (event) => {
            let userId = event.target.getAttribute('data-userid');
            if (userId == null) {
                userId = event.target.parentElement.getAttribute('data-userid');
            }
            let userName = event.target.getAttribute('data-username');
            if (userName == null) {
                userName = event.target.parentElement.getAttribute('data-username');
            }
            this.deleteUser(userId, userName);
        });

        this.customFileInput();
    }

    customFileInput() {
        const fileInput = document.querySelector( ".input-file" );
        const button = document.querySelector( ".input-file-trigger" );
        const the_return = document.querySelector(".file-return");

        if (button && fileInput && the_return) {
            button.addEventListener( "keydown", function( event ) {
                if (event.keyCode == 13 || event.keyCode == 32) {
                    fileInput.focus();
                }
            });

            button.addEventListener( "click", function( event ) {
                fileInput.focus();
                return false;
            });

            fileInput.addEventListener( "change", function( event ) {
                the_return.innerHTML = this.value;
            });
        }
    }

    customFileInputQualipaysage(fileInput, button, the_return) {
        if (button && fileInput && the_return) {
            button.addEventListener( "keydown", function( event ) {
                if (event.keyCode == 13 || event.keyCode == 32) {
                    fileInput.focus();
                }
            });

            button.addEventListener( "click", function( event ) {
                fileInput.focus();
                return false;
            });

            fileInput.addEventListener( "change", function( event ) {
                the_return.innerHTML = this.value;
            });
        }
    }

    cA(individualInput, publicInput, privateInput) {
        const inputs = [
            individualInput,
            publicInput,
            privateInput
        ];

        const total = document.querySelector('#total');
        const totalResult = document.querySelector('#total-result');

        Array.prototype.filter.call(inputs, (input) => {
            total.innerText = this.calculateNbPercent(individualInput, publicInput, privateInput) + '%';
            this.calculateResult(input, parseFloat(totalResult.innerText ? totalResult.innerText.replace(/\s/g, '') : 0));

            input.addEventListener('change', (event) => {
                total.innerText = this.calculateNbPercent(individualInput, publicInput, privateInput) + '%';
                this.calculateResult(event.target, parseFloat(totalResult.innerText ? totalResult.innerText.replace(/\s/g, '') : 0));
            });
        });
    }

    calculateNbPercent(individualInput, publicInput, privateInput) {
        return (parseFloat(individualInput.value ? individualInput.value : 0)+parseFloat(publicInput.value ? publicInput.value : 0)+parseFloat(privateInput.value ? privateInput.value : 0))
    }

    calculateResult(input, total) {
        const result = document.querySelector('#' + input.id + '-result');
        let percentFormPercent = this.percentCalculation(total, input.value ? input.value : 0);

        result.innerText = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(percentFormPercent);
    }

    percentCalculation(a, b) {
        let calculated = (parseFloat(a)*parseFloat(b))/100;
        return parseFloat(calculated);
    }

    handleAccessibleContent() {
        let formSelect = document.getElementById('invite_access');
        let formSelectUpdate = document.getElementById('update_access');
        let accessibleContent = document.getElementById('accessible_content');
        let accessibleContentUpdate = document.getElementById('accessible_content_update');

        if (formSelect.value == 'member_intermediate') {
            accessibleContent.classList.remove('hidden');
        } else {
            accessibleContent.classList.add('hidden');
        }

        if (formSelectUpdate.value == 'member_intermediate') {
            accessibleContentUpdate.classList.remove('hidden');
        } else {
            accessibleContentUpdate.classList.add('hidden');
        }
    }

    getUserData(userName) {
        let formData = new FormData();
        formData.set('username', userName);

        axios
            .post('/espace-adherent/ma-structure/fetch-userdata', formData)
            .then((response) => {
                this.fillEditUser(response.data.data);
            })
            .catch((error) => {

            })
        ;
    }

    fillEditUser(data) {
        let email = document.getElementById("update_email");
        let firstName = document.getElementById("update_firstname");
        let lastName = document.getElementById("update_lastname");
        let userFunction = document.getElementById("update_function");
        let documentAccess = document.getElementById("documents_access_update");
        let adminAccess = document.getElementById("admin_access_update");
        let eudoId = document.getElementById("eudoId");
        let wpId = document.getElementById("wpId");
        let isContactSubscription = document.getElementById("update_contact_subscription");

        email.value = data.email;
        firstName.value = data.first_name;
        lastName.value = data.last_name;
        userFunction.value = data.main_function;
        isContactSubscription.checked = data.isContactSubscription;

        var $select = $("#update_access").selectize();
        var selectize = $select[0].selectize;
        selectize.setValue(data.role);

        if (data.capacities.unep_read_social_document) {
            documentAccess.checked = true;
        } else {
            documentAccess.checked = false;
        }
        if (data.capacities.unep_edit_company_page) {
            adminAccess.checked = true;
        } else {
            adminAccess.checked = false;
        }

        eudoId.value = data.eudoID;
        wpId.value = data.wpID;
    }

    deleteUser(userId, userName) {
        $("#deleteUser").val(userId);
        $("#textDelUser").text('Voulez-vous vraiment supprimer l\'utilisateur ' + userName + ' ?');
    }

    createSkillRow(skills) {
        const skillRows = document.querySelector('#skills-rows');
        const rows = skillRows.childElementCount;

        const mainrow = document.createElement('div');
        mainrow.classList.add('row');
        mainrow.classList.add('skill-' + (rows+1));

        const formgroup1 = document.createElement('div');
        formgroup1.classList.add('form-group');
        formgroup1.classList.add('col-md-6');

        const select1 = document.createElement('select');
        select1.classList.add('cap_lvl_1');
        select1.classList.add('select');
        select1.setAttribute('name', 'skills[' + (rows+1) +'][cap_lvl_1]');

        const option = document.createElement('option');
        option.setAttribute('value', '');
        option.innerText = 'Sélectionner une activité';

        const option2 = document.createElement('option');
        option2.setAttribute('value', '');
        option2.innerText = 'Sélectionner une activité';

        const formgroup2 = document.createElement('div');
        formgroup2.classList.add('form-group');
        formgroup2.classList.add('col-md-5');

        const select2 = document.createElement('select');
        select2.classList.add('cap_lvl_2');
        select2.classList.add('select');
        select2.setAttribute('name', 'skills[' + (rows+1) +'][cap_lvl_2]');

        const formgroup3 = document.createElement('div');
        formgroup3.classList.add('form-group');
        formgroup3.classList.add('col-md-1');

        /*const inputText = document.createElement('input');
        inputText.classList.add('form-control');
        inputText.setAttribute('type', 'text');
        inputText.setAttribute('name', 'skills[' + (rows+1) +'][qualification]');*/

        const inputHidden = document.createElement('input');
        inputHidden.setAttribute('type', 'hidden');
        inputHidden.setAttribute('name', 'skills[' + (rows+1) +'][eudonet_fileId]');

        const button = document.createElement('button');
        button.classList.add('remove-skill');
        button.setAttribute('type', 'button');
        button.innerHTML = '<i class="icon-close"></i>';

        select1.append(option);
        select2.append(option2);

        formgroup1.append(select1);
        formgroup2.append(select2);
        //formgroup3.append(inputText);
        formgroup3.append(inputHidden);
        formgroup3.append(button);

        mainrow.append(formgroup1);
        mainrow.append(formgroup2);
        mainrow.append(formgroup3);

        skillRows.append(mainrow);

        $('.skill-' + (rows+1) + ' .cap_lvl_1').selectize();
        $('.skill-' + (rows+1) + ' .cap_lvl_2').selectize();

        jQuery.each(skills, function(i, val) {
            $('.skill-' + (rows+1) + ' .cap_lvl_1')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.skill-' + (rows+1) + ' .cap_lvl_1').on('change', function() {
            let $value = $(this).val();
            const $row = $(this).closest('.row');
            const $selectorLvl2 = $row.find('.cap_lvl_2');

            axios
                .get('/wp-json/wp/v2/company_skills?parent=' + $value)
                .then((response) => {
                    $selectorLvl2[0].selectize.clear(true);
                    $selectorLvl2[0].selectize.clearOptions();

                    for (let i = 0; i < response.data.length; i++) {
                        let name = htmlentities.decode(response.data[i].name);

                        $selectorLvl2[0].selectize.addOption({
                            value: response.data[i].id,
                            text: name.charAt(0).toUpperCase() + name.slice(1)
                        });
                    }
                });
        });

        $('.skill-' + (rows+1) + ' .remove-skill').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.row').remove();
        });
    }

    createQpSkillRow(skills, status, authorizations, labels) {
        const skillRows = document.querySelector('#skills-qp-rows');
        const rows = skillRows.childElementCount;

        const mainrow = document.createElement('div');
        mainrow.classList.add('d-flex');
        mainrow.classList.add('container-forms-qualipaysage');
        mainrow.classList.add('skill-' + (rows+1));

        const subrow = document.createElement('div');
        subrow.classList.add('form-qualipaysage');
        subrow.classList.add('col-md-11');

        const innerSubrow = document.createElement('div');
        innerSubrow.classList.add('row');

        const formgroup1 = document.createElement('div');
        formgroup1.classList.add('form-group');
        formgroup1.classList.add('col-md-6');

        const label1 = document.createElement('label');
        label1.classList.add('label-size-md');
        label1.setAttribute('value', '');
        label1.innerText = 'Qualification';

        const select1 = document.createElement('select');
        select1.classList.add('qp_lvl_1');
        select1.classList.add('select');
        select1.setAttribute("required", "");
        select1.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_lvl_1]');

        const option = document.createElement('option');
        option.setAttribute('value', '');
        option.innerText = 'Sélectionner une qualification';

        const formgroup2 = document.createElement('div');
        formgroup2.classList.add('form-group');
        formgroup2.classList.add('col-md-6');

        const label2 = document.createElement('label');
        label2.classList.add('label-size-md');
        label2.setAttribute('value', '');
        label2.innerText = 'Statut';

        const select2 = document.createElement('select');
        select2.classList.add('qp_status');
        select2.classList.add('select');
        select2.setAttribute("required", "");
        select2.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_status]');

        const option2 = document.createElement('option');
        option2.setAttribute('value', '');
        option2.innerText = 'Sélectionner un statut';

        const formgroup4 = document.createElement('div');
        formgroup4.classList.add('form-group');
        formgroup4.classList.add('col-md-12');

        const label4 = document.createElement('label');
        label4.classList.add('label-size-md');
        label4.setAttribute('value', '');
        label4.innerText = 'Habilitation';

        const select4 = document.createElement('select');
        select4.classList.add('qp_authorization');
        select4.classList.add('select');
        //select4.setAttribute("required", "");
        select4.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_authorization]');

        const option4 = document.createElement('option');
        option4.setAttribute('value', '');
        option4.innerText = 'Sélectionner une habilitation';

        const formgroup5 = document.createElement('div');
        formgroup5.classList.add('form-group');
        formgroup5.classList.add('col-md-12');

        const label5 = document.createElement('label');
        label5.classList.add('label-size-md');
        label5.setAttribute('value', '');
        label5.innerText = 'Label';

        const select5 = document.createElement('select');
        select5.classList.add('qp_labels');
        select5.classList.add('select');
        //select5.setAttribute("required", "");
        select5.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_labels]');

        const option5 = document.createElement('option');
        option5.setAttribute('value', '');
        option5.innerText = 'Sélectionner un label';

        const formgroup6 = document.createElement('div');
        formgroup6.classList.add('form-group');
        formgroup6.classList.add('col-md-6');

        const label6 = document.createElement('label');
        label6.classList.add('label-size-md');
        label6.setAttribute('value', '');
        label6.innerText = 'Date d\'attribution';

        const input6 = document.createElement('input');
        input6.classList.add('form-control');
        input6.classList.add('form-default');
        input6.setAttribute("required", "");
        input6.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_attribution_start]');
        input6.setAttribute('type', 'date');

        const formgroup7 = document.createElement('div');
        formgroup7.classList.add('form-group');
        formgroup7.classList.add('col-md-6');

        const label7 = document.createElement('label');
        label7.classList.add('label-size-md');
        label7.setAttribute('value', '');
        label7.innerText = 'Date de fin de validité';

        const input7 = document.createElement('input');
        input7.classList.add('form-control');
        input7.classList.add('form-default');
        input7.setAttribute("required", "");
        input7.setAttribute('name', 'qpskills[' + (rows+1) +'][qp_attribution_end]');
        input7.setAttribute('type', 'date');

        const formgroup8 = document.createElement('div');
        formgroup8.classList.add('form-group');
        formgroup8.classList.add('col-md-12');

        const input8 = document.createElement('input');
        input8.classList.add('input-file');
        input8.setAttribute('name', 'qpskills[' + (rows+1) +'][qualification_document]');
        input8.setAttribute('type', 'file');

        const label8 = document.createElement('label');
        label8.classList.add('input-file-trigger');
        label8.classList.add('btn-pill');
        label8.classList.add('btn');
        label8.classList.add('btn-primary');
        label8.innerHTML = 'Envoyer un justificatif<i class="icon-upload ml-1"></i>';

        const paragraph8 = document.createElement('p');
        paragraph8.classList.add('file-return');

        const formgroup3 = document.createElement('div');
        formgroup3.classList.add('form-group');
        formgroup3.classList.add('col-md-1');

        const inputHidden = document.createElement('input');
        inputHidden.setAttribute('type', 'hidden');
        inputHidden.setAttribute('name', 'qpskills[' + (rows+1) +'][eudonet_fileId]');

        const button = document.createElement('button');
        button.classList.add('remove-qualipaysage');
        button.setAttribute('type', 'button');
        button.innerHTML = '<i class="icon-close"></i>';

        select1.append(option);
        select2.append(option2);
        select4.append(option4);
        select5.append(option5);

        formgroup1.append(label1);
        formgroup1.append(select1);

        formgroup2.append(label2);
        formgroup2.append(select2);

        formgroup3.append(inputHidden);
        formgroup3.append(button);

        formgroup4.append(label4);
        formgroup4.append(select4);

        formgroup5.append(label5);
        formgroup5.append(select5);

        formgroup6.append(label6);
        formgroup6.append(input6);

        formgroup7.append(label7);
        formgroup7.append(input7);

        formgroup8.append(input8);
        formgroup8.append(label8);
        formgroup8.append(paragraph8);

        innerSubrow.append(formgroup1);
        innerSubrow.append(formgroup2);
        innerSubrow.append(formgroup4);
        innerSubrow.append(formgroup5);
        innerSubrow.append(formgroup6);
        innerSubrow.append(formgroup7);
        innerSubrow.append(formgroup8);

        mainrow.append(subrow);
        mainrow.append(formgroup3);
        subrow.append(innerSubrow);

        skillRows.append(mainrow);

        $('.skill-' + (rows+1) + ' .qp_lvl_1').selectize();
        jQuery.each(skills, function(i, val) {
            $('.skill-' + (rows+1) + ' .qp_lvl_1')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.skill-' + (rows+1) + ' .qp_status').selectize();
        jQuery.each(status, function (i, val) {
            $('.skill-' + (rows+1) + ' .qp_status')[0].selectize.addOption({
                value: val.id,
                text: val.label
            });
        });

        $('.skill-' + (rows+1) + ' .qp_authorization').selectize();
        jQuery.each(authorizations, function (i, val) {
            $('.skill-' + (rows+1) + ' .qp_authorization')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.skill-' + (rows+1) + ' .qp_labels').selectize();
        jQuery.each(labels, function (i, val) {
            $('.skill-' + (rows+1) + ' .qp_labels')[0].selectize.addOption({
                value: val.id,
                text: val.name
            });
        });

        $('.skill-' + (rows+1) + ' .remove-qualipaysage').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.container-forms-qualipaysage').remove();
        });

        this.customFileInputQualipaysage(input8, label8, paragraph8);
    }
}

export default new ExtranetForms();
